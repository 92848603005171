import CategoryDropdown from "./InputDatabaseDropdown";
import InputDataList from "./InputDataList";
import InputIconProvider from "./InputIconProvider";
import InputMonthSelector from "./InputMonthSelector";
import React, {useState, useEffect} from "react";
import { Tooltip } from 'react-tooltip';
import InputCurrency from "./InputCurrency";
import InputTextField from "./InputTextField";
import InputRadioButton from "./InputRadioButton";
import InputSwitch from "./InputSwitch";
import InputSelect from "./InputSelect";

function FieldComponent({ field, formValues, handleChange, handleDropdownChange, handleCategory, handleIcon, handleType, handleRadio, handleMonthSelector, handleUserTagsChange, userCurrency, handleSwitch, t, linkedCheckValue, setLinkedCheckValue, handleCurrency, monthlyRemainingIncome, annualRemainingIncome }) {

    switch (field.fieldType) {
        case 'radio':
            return (
                <InputRadioButton
                    fieldKey={field.key}
                    options={field.options}
                    callback={(e) => handleRadio(e)}
                    value={formValues[field.key] || field.defaultValue }
                />
            );
        case 'select':
            return (
                    <InputSelect
                        field={field}
                        formValues={formValues}
                        callback={handleType}
                    />
            );
            case 'databasedDropdown':
                return (
                            <CategoryDropdown
                                apiEndpoint={field.apiEndpoint}
                                value={formValues[field.key] || ''}
                                valueLabel={field.valueLabel}
                                valueLabelAddedInfo={field.valueLabelAddedInfo}
                                defaultValue={t(field.placeholder)}
                                onChange={handleDropdownChange}
                                name={field.key}
                                label={t(field.label)}
                            />
                        );
                    case 'dataList':
                        return (
                            <InputDataList
                                apiEndpoint={field.apiEndpoint}
                                selectableValue="category"
                                value={formValues[field.key] || ""}
                                label={field.label}
                                callback={(e) => handleCategory(e)}
                                fieldKey={field.key}
                                placeholder={field.placeholder}
                                tooltip={field.tooltip}
                                required={field.required}
                                disabled={false}
                            />
                        );
                    case 'iconProvider':
                        return (
                            <InputIconProvider
                                currentValue={formValues[field.key] || ''}
                                onChange={handleIcon}
                                name={field.key || ''}
                                tooltip={field.tooltip}
                            />
                        );
                    case 'user_tags':
                        return (
                            <div className="mb-3">
                                <label htmlFor={field.key} className="form-label">{t(field.label)}
                                    <small>{t('list_separated_by_space')}</small></label>
                                <i className="bi bi-question-circle ms-1" data-tooltip-id={`tooltip-${field.key}`}></i>
                                <Tooltip id={`tooltip-${field.key}`} place="right" effect="solid">
                                    {t(field.tooltip)}
                                </Tooltip>
                                <input
                                    type="text"
                                    className="form-control"
                                    placeholder={field.placeholder}
                                    id={field.key}
                                    name={field.key}
                                    value={formValues[field.key] ? formValues[field.key].map(tag => t(tag).toLowerCase()).join(' ') : []}
                                    onChange={handleUserTagsChange}
                                />
                            </div>
                        );
                    case 'monthSelector':
                    return (
                    <InputMonthSelector
                        fieldName={field.key}
                        months={formValues[field.key]}
                        onSelect={handleMonthSelector}
                        tooltip={field.tooltip}
                    />
                    );
                    case 'currency':
                        return (
                            <InputCurrency
                                fieldKey={field.key}
                                required={field.required}
                                tooltip={field.tooltip}
                                label={field.label}
                                value={formValues[field.key]}
                                callback={handleCurrency}
                                userCurrency={userCurrency}
                                disabled={false}
                                isLinked={field.isLinked || false}
                                linkedCheckValue={linkedCheckValue}
                                setLinkedCheckValue={setLinkedCheckValue}
                                monthlyRemainingIncome={monthlyRemainingIncome}
                                annualRemainingIncome={annualRemainingIncome}
                            />
                        );
                    case 'percent':
                        return (
                            <div className="mb-3 percent-input">
                                <label htmlFor={field.key} className="form-label">{t(field.label)}</label>
                                <i className="bi bi-question-circle ms-1" data-tooltip-id={`tooltip-${field.key}`}></i>
                                <Tooltip id={`tooltip-${field.key}`} place="right" effect="solid">
                                    {t(field.tooltip)}
                                </Tooltip>
                                <div className="input-group">
                                    <span className="input-group-text">%</span>
                                    <input
                                        type="number"
                                        className="form-control"
                                        id={field.key}
                                        name={field.key}
                                        value={formValues[field.key] || 0}
                                        onChange={handleChange}
                                        placeholder={`0.00`}
                                        required={field.required}
                                        step="0.01"
                                    />
                                </div>
                            </div>
                        );
                    case ("switch"): {
                        return (
                            <InputSwitch
                                fieldKey={field.key}
                                checked={formValues[field.key]}
                                tooltip={field.tooltip}
                                label={field.label}
                                required={field.required}
                                callback={handleSwitch}
                            />
                        );
                    }
                    default:
                        return (
                            <InputTextField
                                field={field}
                                formValues={formValues}
                                callback={handleChange}
                            />
                        );
    }
}

export default FieldComponent;