import React, { useState, useEffect } from "react";
import { useTranslation } from 'react-i18next';
import FieldComponent from "../../logical-ui-elements/FieldComponent";
import { updateDataById, addDataByKey } from '../../../services/indexedDB';
import useEncryptFormValues from './../../../custom-hooks/useEncryption';
import EncryptionService from "../../../services/EncryptionService";
import "./CreateEditForm.css";
import TemplatePicker from "../template-picker/TemplatePicker";
function CreateEditForm({ formFields, initialValues = {}, onFormSubmit, location, setChange, annualRemainingIncome, monthlyRemainingIncome }) {
  const { t } = useTranslation();
  const { encryptFormValues } = useEncryptFormValues(formFields);

  const [showTemplatePicker, setShowTemplatePicker] = useState(false);


  // Initialize formValues with default values
  const initializeFormValues = () => {
    const values = { ...initialValues };
    if(values.id) {
      if(EncryptionService.checkIfKeyExists()) {
        formFields.forEach(field => {
          if( field.isEncrypted) {
            if(Array.isArray(values[field.key])) {
                values[field.key] = values[field.key].map(value => {
                    return EncryptionService.decrypt(value, values.iv);
                });
            } else {
              values[field.key] = EncryptionService.decrypt(values[field.key], values.iv);
            }
          } else {
            values[field.key] = values[field.key];
          }
        });
      }
    }
    return values;
  };

  const [formValues, setFormValues] = useState(initializeFormValues);
  const [linkedCheckValue, setLinkedCheckValue] = useState(true);

  const setTemplateValues = (template) => {
    const translatedTemplate = {
      ...template,
      title: t(template.title),
      category: t(template.category),
      user_tags: template.user_tags.length > 0 ? template.user_tags.map(tag => t(tag)) : []
    };
    setFormValues((prev) => ({ ...prev, ...translatedTemplate }));
  }

  useEffect(() => {
    setFormValues(initializeFormValues);
  }, [initialValues]);

  useEffect(() => {
    window.scrollTo(0, 0);
  }, [showTemplatePicker]);


  if (!Array.isArray(formFields)) {
    return null; // or a loading indicator
  }

  const handleChange = (e) => {
    const { name, value } = e.target;
    setFormValues((prev) => ({ ...prev, [name]: value }));
  };

  const handleDropdownChange = (e, name) => {
    const value = e.target.value;
    setFormValues((prev) => ({ ...prev, [name]: value }));
  }

  const handleCategory = (e) => {
    const { name, value } = e.target;
    setFormValues((prev) => ({ ...prev, [name]: value }));
  }

  const handleIcon = (name, value) => {
    setFormValues((prev) => ({ ...prev, [name]: value }));
  }

  const handleType = (e) => {
    const { name, value } = e.target;
    setFormValues((prev) => ({ ...prev, [name]: value }));
  }

  const handleMonthSelector = (name, value) => {
    setFormValues((prev) => ({ ...prev, [name]: value }));
  }

  const handleSwitch = (e) => {
    const {name, checked} = e.target;
    setFormValues((prev) => ({...prev, [name]: checked}));
  }

  const handleCurrency = (e, linkedFieldKey) => {
    const { name, value } = e.target;
    setFormValues((prev) => ({ ...prev, [name]: value }));
    if (linkedCheckValue && formFields.find(field => field.isLinked && field.key === name)) {
      const linkedField = formFields.find(field => field.isLinked && field.key === name);
      const linkedValue = linkedField.operation === "multiply" ? value * linkedField.factor : value / linkedField.factor;
      setFormValues((prev) => ({ ...prev, [linkedField.linkedField]: parseFloat(linkedValue.toFixed(2)) }));
    }
  }

  const handleRadio = (e) => {
    const { name, value } = e.target;
    setFormValues((prev) => ({ ...prev, [name]: value }));
  }

  const handleUserTagsChange = (e) => {
    const input = e.target.value;
    try {
      const tagsArray = input.split(' ').map(tag => tag.trim());
      const { name } = e.target;
      setFormValues((prev) => ({ ...prev, [name]: tagsArray }));
    } catch (error) {
      console.error("Error parsing user tags:", error);
      const { name } = e.target;
      setFormValues((prev) => ({ ...prev, [name]: [] }));
    }
  }

  const cancelSubmission = () => {
    onFormSubmit();
  }

  const handleSubmit = async (e) => {
    e.preventDefault();

    const encryptedFormValues = await encryptFormValues(formValues);
    const data = {
      ...encryptedFormValues,
    };

    try {
      if (formValues.id) {
        // Update existing position
        await updateDataById(location, parseInt(formValues.id), data);
        await updateDataById('user', 0, { last_save: new Date().toISOString() });
      } else {
        // Create new position
        await addDataByKey(location, data);
        await updateDataById('user', 0, { last_save: new Date().toISOString() });
      }
      setChange(true);
      onFormSubmit();

    } catch (error) {
      console.error('Error saving position:', error);
    }
  };

  const requiredFields = formFields
  .filter(field => field.required)
  .sort((a, b) => (a.order ?? Infinity) - (b.order ?? Infinity));

  const optionalFields = formFields
  .filter(field => !field.required)
  .sort((a, b) => (a.order ?? Infinity) - (b.order ?? Infinity));

  return (
    <div className={`container CreateEditFormComponent ${location}-form mt-4 mb-5`}>
      {showTemplatePicker ? (
          <TemplatePicker
                callback={setTemplateValues}
                setShowTemplatePicker={setShowTemplatePicker}
                monthlyRemainingIncome={monthlyRemainingIncome}
                annualRemainingIncome={annualRemainingIncome}
          />
      ) : (
          <form onSubmit={handleSubmit}>
            <h3 className="mt-3 mb-5">{t('create-edit-form-required-fields')}</h3>
            {requiredFields.map((field, index) => (
                <FieldComponent
                    key={index}
                    field={field}
                    formValues={formValues}
                    handleChange={handleChange}
                    handleDropdownChange={handleDropdownChange}
                    handleCategory={handleCategory}
                    handleIcon={handleIcon}
                    handleType={handleType}
                    handleRadio={handleRadio}
                    handleMonthSelector={handleMonthSelector}
                    handleUserTagsChange={handleUserTagsChange}
                    handleSwitch={handleSwitch}
                    handleCurrency={handleCurrency}
                    linkedCheckValue={linkedCheckValue}
                    setLinkedCheckValue={setLinkedCheckValue}
                    monthlyRemainingIncome={monthlyRemainingIncome}
                    annualRemainingIncome={annualRemainingIncome}
                    t={t}
                />
            ))}
            <h3 className="mt-3 mb-5">{t('create-edit-form-optional-fields')}</h3>
            {optionalFields.map((field, index) => (
                <FieldComponent
                    key={index}
                    field={field}
                    formValues={formValues}
                    handleChange={handleChange}
                    handleDropdownChange={handleDropdownChange}
                    handleCategory={handleCategory}
                    handleIcon={handleIcon}
                    handleRadio={handleRadio}
                    handleType={handleType}
                    handleMonthSelector={handleMonthSelector}
                    handleUserTagsChange={handleUserTagsChange}
                    handleSwitch={handleSwitch}
                    handleCurrency={handleCurrency}
                    linkedCheckValue={linkedCheckValue}
                    setLinkedCheckValue={setLinkedCheckValue}
                    monthlyRemainingIncome={monthlyRemainingIncome}
                    annualRemainingIncome={annualRemainingIncome}
                    t={t}
                />
            ))}
            <div className="fixed-buttons-container">
              <button type="button" className="btn btn-secondary" onClick={cancelSubmission}>
                <i className="bi bi-arrow-return-left"/>
              </button>
              <button type="submit" className="btn btn-success">
                <i className="bi bi-floppy"/>
              </button>
              <button
                  type="button"
                  className="btn btn-primary"
                  onClick={() => setShowTemplatePicker(true)}
              >
                <i className="bi bi-file-earmark-plus"></i>
              </button>
            </div>
          </form>
      )}
    </div>
  );
}

export default CreateEditForm;