import React from 'react';

function FormatDate({dateString}) {
    const date = new Date(dateString);
    return date.toLocaleDateString('de-CH', {
        day: 'numeric',
        month: 'long',
        year: 'numeric'
    });
}

export default FormatDate;