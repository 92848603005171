import React, { useEffect, useState } from "react";
import { getDataById } from "../../services/indexedDB";
import JsonHandlingService from "../../services/JsonHandlingService";
import EncryptionService from "../../services/EncryptionService";

function JsonFileExport() {
    const [username, setUsername] = useState("");
    const [dateTimeString, setDateTimeString] = useState("");

    const getUser = async () => {
        const user = await getDataById('user', 0);
        setUsername(EncryptionService.decrypt(user.username, user.iv));
    }

    const createTimestamp = () => {
        const now = new Date();
        const year = now.getFullYear();
        const month = String(now.getMonth() + 1).padStart(2, '0');
        const day = String(now.getDate()).padStart(2, '0');
        const hours = String(now.getHours()).padStart(2, '0');
        const minutes = String(now.getMinutes()).padStart(2, '0');

        setDateTimeString( `${year}-${month}-${day} ${hours}:${minutes}`);
    }

    useEffect(() => {
        getUser();
        createTimestamp();
    }, []);

    const handleExportClick = async () => {
        await JsonHandlingService.exportDataToJsonFile(username, dateTimeString);
    };

    return (
        <button onClick={handleExportClick}>
            Export IndexedDB to JSON
        </button>
    );
}

export default JsonFileExport;