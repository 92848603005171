import React, {useState, useEffect} from 'react';

function DeleteItem({ setChange }) {
    return (
        <div className="container">
            <div className="row">
                <h1>I'm working this far</h1>
            </div>
        </div>
    );
}

export default DeleteItem;