import {FontAwesomeIcon} from "@fortawesome/react-fontawesome";
import {useState, useEffect} from "react";

function ViewSearch (props) {

    const [initialValueList, setInitialValueList] = useState(props.initialValueList);

    const searchFunction = (event) => {
        let searchValue = event.target.value.toLowerCase();
        let searchResults = initialValueList.filter(item => {
            let loopItem = Object.keys(item).map(key => {
                if (typeof item[key] === 'string') {
                    return item[key].toLowerCase().includes(searchValue);
                }
                return null;
            });
            return loopItem.includes(true);
        });
        props.setNewValueList(searchResults);
    }

    useEffect (() => {
        setInitialValueList(props.initialValueList);
    }, [props.initialValueList]);

  return (
      <div className="row">
          <div className="col-12">
              <div className="input-group mb-3">
                  <input type="text" className="form-control" placeholder="Search Positions..."
                         aria-label="Search Positions..." aria-describedby="basic-addon2"
                         onChange={(e) => {searchFunction(e)}}/>
                  <span className="input-group-text" id="basic-addon2">
                          <FontAwesomeIcon icon={`fas fa-search`} className="search-icon fa-fw"/>
                      </span>
              </div>
          </div>
      </div>

  );
}

export default ViewSearch;
