import React, {useEffect, useState} from "react";
import { useNavigate } from "react-router-dom";
import {deleteDataById, updateDataById} from "../../../services/indexedDB";
import { useTranslation } from "react-i18next";
import EncryptionService from "../../../services/EncryptionService";


function DeleteForm( { values, onFormSubmit, onCancel, location_id, setChange } ) {

    const [id, setDeleteId] = React.useState(null);
    const navigate = useNavigate();
    const { t } = useTranslation();
    const [deleteTitle, setDeleteTitle] = useState();

    useEffect (() => {
        setDeleteId(values.id);
        setDeleteTitle(EncryptionService.decrypt(values.title, values.iv));
    }, [values.id, values.title]);

    const deleteFinancialPosition = async () => {
        try {
            await deleteDataById(location_id, id);
            await updateDataById('user', 0, {last_save: new Date().toISOString()});
            onFormSubmit();
            setChange(true);
            navigate(`/${location_id}`);
        } catch (error) {
            console.error('Error deleting financial position:', error);
            return { error: 'Error deleting financial position' };
        }
    };

    const cancelAction = () => {
        onCancel();
    }

    console.log(values.title);
    console.log(values.iv);
    console.log(deleteTitle);

    return (
        <div className={`container ${location_id}-form mt-4 mb-5`}>
            <form>
                <p>Are you sure you want to delete the "{deleteTitle}" entry?</p>

                <div className="fixed-buttons-container">
                    <button type="button" className="btn btn-secondary" onClick={cancelAction}>
                        <i className="bi bi-x-lg"></i>
                    </button>
                    <button type="button" className="btn btn-danger" onClick={deleteFinancialPosition}>
                        <i className="bi bi-trash"></i>
                    </button>
                </div>
            </form>
        </div>
    );
}

export default DeleteForm;
