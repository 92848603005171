import React, {useState, useEffect} from "react";
import "./Loading.css";

function Loading() {
    const  [cleverText, setCleverText] = useState("Loading...");

    const financialWisdom = [
        "Spend less than you earn.",
        "Start investing early.",
        "Save for emergencies.",
        "Avoid high-interest debt.",
        "Track your expenses.",
        "Live within your means.",
        "Prioritize needs over wants.",
        "Build an emergency fund.",
        "Invest in yourself.",
        "Don’t rely solely on credit.",
        "Understand compound interest.",
        "Set financial goals.",
        "Avoid impulse buys.",
        "Review your budget monthly.",
        "Save for retirement early.",
        "Diversify your investments.",
        "Money isn’t everything.",
        "Avoid lifestyle inflation.",
        "Plan for big purchases.",
        "Learn from financial mistakes."
    ];

    useEffect(() => {
        setTimeout(() => {
            const randomIndex = Math.floor(Math.random() * financialWisdom.length);
            setCleverText(financialWisdom[randomIndex]);
        }, 7500);
    }, [cleverText]);

    useEffect(() => {
        const randomIndex = Math.floor(Math.random() * financialWisdom.length);
        setCleverText(financialWisdom[randomIndex]);
    }, [] );

    return (
        <div className="container">
            <div className="row">
                <div className="col positioner text-center">
                    <div className="lds-ellipsis">
                        <div></div>
                        <div></div>
                        <div></div>
                        <div></div>
                    </div>
                    <h1 className="clever-text fst-italic">{cleverText}</h1>
                </div>
            </div>
        </div>
    );
}

export default Loading;
