import React, { useEffect, useState } from 'react';
import { HashRouter as Router, Route, Routes, Navigate, useLocation, useNavigate } from 'react-router-dom';
import bootstrap from 'bootstrap/dist/js/bootstrap.bundle.min';
import MainMenuOverviewPage from './core-components/main-menu-overview-page/MainMenuOverviewPage';
import UserSettings from "./core-components/user-settings/UserSettings";
import Dashboard from './core-components/dashboard/Dashboard';
import ProtectedRoute from './components/ProtectedRoute';
import Logout from './core-components-unprotected/Logout';
import Navigation from "./components/generic-view-components/navigation/Navigation";
import BudgetCheckProcess from "./core-components/budget-checks/BudgetCheckProcess";
import DetailViewPage from "./core-components/detail-view-page/DetailViewPage";
import Login from "./core-components-unprotected/Login";
import Onboarding from "./core-components-unprotected/Onboarding";
import Footer from "./components/generic-view-components/footer/Footer";
import AddNewItem from "./core-components/main-menu-overview-page/add-new-item-form/AddNewItem";
import TemplatePositions from './components/generic-view-components/template-picker/TemplatePositions';
import userSessionManager from './services/userSessionManager';
import TemplatePicker from "./components/generic-view-components/template-picker/TemplatePicker";
import DeleteItem from "./core-components/detail-view-page/edit-delete-actions/DeleteItem";
import './App.css';
import DailySpending from "./core-components/daily-spending/DailySpending";
import BudgetChecks from "./core-components/budget-checks/BudgetChecks";


function App() {
    const location = useLocation();
    const navigate = useNavigate();
    const [change, setChange] = useState(() => {
        const savedChange = localStorage.getItem('change');
        return savedChange ? JSON.parse(savedChange) : false;
    });

    useEffect(() => {
        localStorage.setItem('change', JSON.stringify(change));
    }, [change]);

    useEffect(() => {
        const handlePopState = (event) => {
            event.preventDefault();
            window.history.pushState(null, '', window.location.href);
        };

        window.addEventListener('popstate', handlePopState);

        return () => {
            window.removeEventListener('popstate', handlePopState);
        };
    }, []);

    useEffect(() => {
        const updateSessionTimestamp = () => {
            userSessionManager.updateTimestamp();
        };

        window.addEventListener('click', updateSessionTimestamp);
        window.addEventListener('keydown', updateSessionTimestamp);
        window.addEventListener('mousemove', updateSessionTimestamp);

        return () => {
            window.removeEventListener('click', updateSessionTimestamp);
            window.removeEventListener('keydown', updateSessionTimestamp);
            window.removeEventListener('mousemove', updateSessionTimestamp);
        };
    }, []);

    useEffect(() => {
        const isSessionValid = userSessionManager.isSessionValid();
        const excludedPaths = ['/', '/login', '/home', '/onboarding'];
        if (!isSessionValid && !excludedPaths.includes(location.pathname)) {
            localStorage.removeItem('userSession');
            navigate('/login', { state: { message: 'Your session has expired' } });
        }
    }, [location]);

    useEffect(() => {
        const tooltipTriggerList = [].slice.call(document.querySelectorAll('[data-bs-toggle="tooltip"]'));
        tooltipTriggerList.map(function (tooltipTriggerEl) {
            return new bootstrap.Tooltip(tooltipTriggerEl);
        });
    }, [location]);

    const showNavigation = userSessionManager.isSessionValid() && !['/login', '/onboarding', '/home'].includes(location.pathname);

    return (
        <div className="App">
            {showNavigation &&
                <Navigation
                    change={change}
                    setChange={setChange}
                />
            }
            <Routes>
                <Route path="/" element={<Navigate to="/login" />} />
                <Route path="/login" element={<Login />} />
                <Route path="/onboarding" element={<Onboarding />} />
                <Route path="/user-settings" element={
                    <ProtectedRoute>
                        <UserSettings
                            setChange={setChange}
                        />
                    </ProtectedRoute>
                }/>
                <Route path="/financial-positions" element={
                    <ProtectedRoute>
                        <MainMenuOverviewPage
                            setChange={setChange}
                        />
                    </ProtectedRoute>
                }/>
                <Route path="/financial-positions/details/:pos_id" element={
                    <ProtectedRoute>
                        <DetailViewPage
                            setChange={setChange}
                        />
                    </ProtectedRoute>
                }/>
                <Route path="/budget-checks/details/:bcId" element={
                    <ProtectedRoute>
                        <BudgetCheckProcess
                            setChange={setChange}
                        />
                    </ProtectedRoute>
                }/>
                <Route path="/:location/add-new" element={
                    <ProtectedRoute>
                        <AddNewItem
                            setChange={setChange}
                        />
                    </ProtectedRoute>
                }/>
                <Route path="/:location/details/delete/:itemId" element={
                    <ProtectedRoute>
                        <DeleteItem setChange={setChange} />
                    </ProtectedRoute>
                }/>
                <Route path="/:location/template" element={
                    <ProtectedRoute>
                        <TemplatePicker
                            setChange={setChange}
                        />
                    </ProtectedRoute>
                }/>
                <Route path="/daily-spending" element={
                    <ProtectedRoute>
                        <DailySpending />
                    </ProtectedRoute>
                }/>
                <Route path="/budget-checks" element={
                    <ProtectedRoute>
                        <BudgetChecks />
                    </ProtectedRoute>
                }/>
                <Route path="/:location/template/:templateKey" element={
                    <ProtectedRoute>
                        <TemplatePositions
                            setChange={setChange}
                        />
                    </ProtectedRoute>
                }/>
                <Route path="/Dashboard" element={
                    <ProtectedRoute>
                        <Dashboard />
                    </ProtectedRoute>
                }/>
                <Route path="/logout" element={<Logout />} />
            </Routes>
            <Footer/>
        </div>
    );
}

function AppWrapper() {
    return (
        <Router>
            <App/>
        </Router>
    );
}

export default AppWrapper;