const userSessionManager = {
    openSession: async (key) => {
        const timestamp = new Date().toISOString();
        localStorage.setItem('userSession', JSON.stringify({
            token: key,
            timestamp: timestamp
        }));
    },

    updateTimestamp: () => {
        if(userSessionManager.isSessionValid()) {
            const session = JSON.parse(localStorage.getItem('userSession'));
            if (!session) {
                throw new Error('No session found');
            }

            // Update the timestamp
            session.timestamp = new Date().toISOString();
            localStorage.setItem('userSession', JSON.stringify(session));
        }
    },

    getPassword: () => {
        const session = JSON.parse(localStorage.getItem('userSession'));
        if (!session) {
            throw new Error('No session found');
        }

        const sessionTimestamp = new Date(session.timestamp);
        const currentTime = new Date();
        const timeDifference = (currentTime - sessionTimestamp) / (1000 * 60); // time difference in minutes

        if (timeDifference > 30) {
            throw new Error('Session expired');
        }

        return session.password;
    },

    isSessionValid: () => {
        try {
            const session = JSON.parse(localStorage.getItem('userSession'));
            if (!session) {
                return false;
            }

            const sessionTimestamp = new Date(session.timestamp);
            const currentTime = new Date();
            const timeDifference = (currentTime - sessionTimestamp) / (1000 * 60); // time difference in minutes

            return timeDifference <= 30;
        } catch (error) {
            return false;
        }
    }
};

export default userSessionManager;