import React, { useState } from 'react';
import { useNavigate } from 'react-router-dom';
import { addDataByKey, clearAllData } from '../services/indexedDB';
import EncryptionService from '../services/EncryptionService';
import userSessionManager from '../services/userSessionManager';
import i18n from './../configurations/i18n';
import InstallAppBanner from "./InstallAppBanner";

const Onboarding = () => {
    const [username, setUsername] = useState('');
    const [language, setLanguage] = useState('en');
    const [currency, setCurrency] = useState('CHF');
    const [payday, setPayday] = useState(25);
    const [password, setPassword] = useState('');
    const [confirmPassword, setConfirmPassword] = useState('');
    const [error, setError] = useState('');
    const [encryptionMode, setEncryptionMode] = useState(false);
    const [termsRead, setTermsRead] = useState(false);

    const [passwordCriteria, setPasswordCriteria] = useState({
        length: false,
        uppercase: false,
        lowercase: false,
        number: false,
        specialChar: false,
    });
    const { t } = i18n;
    const navigate = useNavigate();

    const handleSubmit = async (e) => {
        e.preventDefault();
        if (password !== confirmPassword) {
            setError(t('password-match-error'));
            return;
        }

        try {
            await clearAllData(); // Clear all data from IndexedDB
            if(encryptionMode) {
                const iv = EncryptionService.generateIv();
                const salt = await EncryptionService.generateSalt();
                const key = await EncryptionService.generateKey(password, salt);
                await userSessionManager.openSession(key);
                const encryptedUsername = await EncryptionService.encrypt(username, iv);
                await addDataByKey('user', { username: encryptedUsername, id: 0, payday: payday, language: language, currency: currency, salt: salt, iv: iv, encryptionMode: encryptionMode });
            } else {
                await addDataByKey('user', { username, id: 0, payday: payday, language: language, currency: currency});
                await userSessionManager.openSession("");
            }
            navigate('/financial-positions');
        } catch (error) {
            setError(t('registration-error'));
            console.error('Error registering user:', error);
        }
    };

    const validatePassword = (password) => {
        const criteria = {
            length: password.length >= 8,
            uppercase: /[A-Z]/.test(password),
            lowercase: /[a-z]/.test(password),
            number: /[0-9]/.test(password),
            specialChar: /[!@#$%^&*(),.?":{}|<>]/.test(password),
        };
        setPasswordCriteria(criteria);
    };

    return (
        <div className="container">
            {!termsRead ? (
                <div className="row">
                    <div className="col-12 text-center">
                        <h1 className="mt-3 text-center">{t('registration-process-onboarding')}</h1>
                        <div className="alert alert-warning">
                            {t('registration-process-onboarding-warning')}
                        </div>
                        <h5 className="text-center mt-3">{t('registration-process-onboarding-welcome')}</h5>
                        <span className="small text-justify">{t('registration-process-onboarding-description')}</span>
                        <br/>
                        <br/>
                        <span className="small mt-2">{t('registration-process-onboarding-local-storage')}</span>
                        <br />
                        <br />
                        <span className="small mt-2">{t('registration-process-onboarding-terms')}</span>
                        <br/>
                        <button type="button" className="btn btn-success mt-5" onClick={() => setTermsRead(true)}><i className="bi bi-check-lg" /> {t('registration-process-onboarding-okay')}</button>
                    </div>
                </div>
            ) : (
                <>
                <div className="row">
                    <div className="col-12">
                        <h1 className="mt-3 text-center">{t('onboarding')}</h1>
                        <form onSubmit={handleSubmit}>
                            <div className="form-group">
                                <div className="mb-3">
                                    <label className="form-label" htmlFor="username">{t('username')}</label>
                                    <input
                                        className="form-control"
                                        type="text"
                                        id="username"
                                        value={username}
                                        onChange={(e) => setUsername(e.target.value)}
                                        required
                                    />
                                </div>
                            </div>
                                <div className="mb-3">
                                    <label className="form-label">{t('language')}</label>
                                    <select
                                        name="language"
                                        className="form-select"
                                        value={language}
                                        onChange={(e) => setLanguage(e.target.value)}
                                        required
                                    >
                                        <option value="de">{t('german')}</option>
                                        <option value="it">{t('italian')}</option>
                                        <option value="fr">{t('french')}</option>
                                        <option value="en">{t('english')}</option>
                                    </select>
                                </div>
                                <div className="mb-3">
                                    <label className="form-label">{t('default_currency')}</label>
                                    <select
                                        name="currency"
                                        className="form-select"
                                        value={currency}
                                        onChange={(e) => setCurrency(e.target.value)}
                                        required
                                    >
                                        <option value="CHF">{t('chf')}</option>
                                        <option value="EUR">{t('eur')}</option>
                                        <option value="USD">{t('usd')}</option>
                                    </select>
                                </div>
                                <div className="mb-5">
                                    <label className="form-label">{t('budget-check-date')}</label>
                                    <input
                                        type="number"
                                        className="form-control"
                                        value={payday}
                                        name="payday"
                                        onChange={(e) => setPayday(e.target.value)}
                                        required
                                    />
                                    <div id="budget-check-help"
                                         className="form-text fst-italic">{t('budget-check-help')}</div>
                                </div>
                                <hr className="mb-5"/>
                                <div className="text-center">
                                    <h5 className="text-center">{t('encryption')}</h5>
                                    <span className="small">{t('encryption-description')}</span>
                                </div>
                                <div className="form-check form-switch mt-5 mb-5 d-flex justify-content-center">
                                    <input
                                        className="form-check-input"
                                        type="checkbox"
                                        role="switch"
                                        id="encryptionMode"
                                        checked={encryptionMode}
                                        onChange={(e) => setEncryptionMode(e.target.checked)}
                                    />
                                    <label className="form-check-label ms-2"
                                           htmlFor="encryptionMode">{t('encryption-mode')}</label>
                                </div>
                                {encryptionMode && (
                                    <>
                                        <div className="mb-3">
                                            <div className="form-group">
                                                <label className="form-label" htmlFor="password">{t('password')}</label>
                                                <input
                                                    type="password"
                                                    className="form-control"
                                                    id="password"
                                                    value={password}
                                                    onChange={(e) => {
                                                        setPassword(e.target.value);
                                                        validatePassword(e.target.value);
                                                    }}
                                                    required
                                                />
                                            </div>
                                        </div>
                                        <div className="mb-3">
                                            <div className="form-group">
                                                <label className="form-label" htmlFor="confirmPassword">{t('confirm-password')}</label>
                                                <input
                                                    type="password"
                                                    className="form-control"
                                                    id="confirmPassword"
                                                    value={confirmPassword}
                                                    onChange={(e) => setConfirmPassword(e.target.value)}
                                                    required
                                                />
                                            </div>
                                        </div>
                                        <div className="password-criteria text-center">
                                            <p>{t('password-criteria')}</p>
                                            <ul className="list-group mb-5 ms-5 me-5">
                                                <li className={passwordCriteria.length ? 'list-group-item list-group-item-success' : 'list-group-item list-group-item-danger'}>{t('password-length')}</li>
                                                <li className={passwordCriteria.uppercase ? 'list-group-item list-group-item-success' : 'list-group-item list-group-item-danger'}>{t('password-uppercase')}</li>
                                                <li className={passwordCriteria.lowercase ? 'list-group-item list-group-item-success' : 'list-group-item list-group-item-danger'}>{t('password-lowercase')}</li>
                                                <li className={passwordCriteria.number ? 'list-group-item list-group-item-success' : 'list-group-item list-group-item-danger'}>{t('password-number')}</li>
                                                <li className={passwordCriteria.specialChar ? 'list-group-item list-group-item-success' : 'list-group-item list-group-item-danger'}>{t('password-special-char')}</li>
                                            </ul>
                                        </div>
                                    </>
                                )}

                                {error && <div className="error">{error}</div>}
                            <div className="text-center">
                                <button type="submit" className="btn btn-success">{t('register')}</button>
                            </div>
                        </form>
                    </div>
                </div>
                </>
            )}
        </div>
    );
};

export default Onboarding;