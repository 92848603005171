import React from 'react';

function YieldInfo({label, positionYield, color}) {

    return (
        <div className="col-md-4 mb-4">
            <div className={`card h-100 bg-opacity-25 ${color}`}>
                <div className="card-body text-center mt-5 mb-5">
                    <h3>{label}</h3>
                    <h1 className="display-5">{positionYield}%</h1>
                </div>
            </div>
        </div>
    );
}

export default YieldInfo;