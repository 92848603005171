import React, { useEffect, useState } from "react";
import { getDataById, getDataByKey, updateDataById } from "../../services/indexedDB";
import { useParams, useNavigate } from "react-router-dom";
import i18n from "../../configurations/i18n";
import Loading from "../../components/generic-view-components/loading/Loading";
import TransactionSection from "./TransactionSection";
import budgetCheckConfig from "./budgetCheckConfig";
import FinalView from "./FinalView";
import EncryptionService from "../../services/EncryptionService";

function BudgetCheckProcess({ setChange }) {
    const { bcId } = useParams();
    const { t } = i18n;
    const navigate = useNavigate();
    const budgetSectionsConfig = budgetCheckConfig.budgetCheckSections;

    const [loading, setLoading] = useState(true);
    const [budgetCheckLoading, setBudgetCheckLoading] = useState(true);
    const [transactionsLoading, setTransactionsLoading] = useState(true);
    const [pageIndex, setPageIndex] = useState(0);

    const [budgetSections, setbudgetSections] = useState([]);
    const [budgetCheckScore, setBudgetCheckScore] = useState(0);
    const [budgetDeviation, setBudgetDeviation] = useState(0);
    const [budgetCheck, setBudgetCheck] = useState({});
    const [budgetCheckTransactions, setBudgetCheckTransactions] = useState({});

    const fetchBudgetCheck = async () => {
        const fetchedData = await getDataById('budget-checks', parseInt(bcId));
        const loadingInterval = setTimeout(() => {
            if (fetchedData) {
                setBudgetCheck(fetchedData);
                setBudgetCheckLoading(false);
                clearInterval(loadingInterval);
            }
        }, 1000);
    }

    const fetchBudgetCheckTransactions = async () => {
        const fetchedData = await getDataByKey('transactions', 'budget_check_id', parseInt(bcId));
        const loadingInterval = setTimeout(() => {
            if (fetchedData) {
                setBudgetCheckTransactions(fetchedData);
                setTransactionsLoading(false);
                clearInterval(loadingInterval);
            }
        }, 1000);
    }

    useEffect(() => {
        setLoading(true);
        fetchBudgetCheck();
        fetchBudgetCheckTransactions();
    }, [bcId]);

    useEffect(() => {
        if(budgetCheck.closed) {
            setPageIndex(1);
        }
    }, [budgetCheck]);

    useEffect(() => {
        if (budgetCheckTransactions.length > 0) {
            const budgetCheckSections = budgetSectionsConfig.map(section => {
                if(section.type === 'income') {
                    return {
                        ...section,
                        transactions: budgetCheckTransactions.filter(transaction => transaction.type === 'income')
                    }
                }
                if(section.type === 'regular') {
                    return {
                        ...section,
                        transactions: budgetCheckTransactions.filter(transaction => transaction.type !== 'income' && transaction.type !== 'savings' && transaction.transaction_type === 'regular')
                    }
                }
                if(section.type === 'irregular') {
                    return {
                        ...section,
                        transactions: budgetCheckTransactions.filter(transaction => transaction.type !== 'income' && transaction.type !== 'savings' && transaction.transaction_type === 'irregular')
                    }
                }
                if(section.type === 'savings') {
                    return {
                        ...section,
                        transactions: budgetCheckTransactions.filter(transaction => transaction.type === 'savings')
                    }
                }
            });
            setbudgetSections(budgetCheckSections)
        }
    }, [budgetCheckTransactions]);

    useEffect(() => {
        if (budgetCheckLoading === false && transactionsLoading === false) {
            setLoading(false);
        }
    }, [budgetCheckLoading, transactionsLoading]);

    const closeBudgetCheck = async () => {
        console.log(budgetCheck);
        var iv =""
        var bDeviation = null;

        if(EncryptionService.checkIfKeyExists()) {
            iv = EncryptionService.generateIv();
            bDeviation = EncryptionService.encrypt(budgetDeviation.toString(), iv);
        }

        const data = {
            ...budgetCheck,
            score: budgetCheckScore,
            budget_deviation: bDeviation !== null ? bDeviation : budgetDeviation,
            closed: true,
            iv: iv
        }
        await updateDataById('budget-checks', parseInt(bcId), data);
        setChange(true);
        navigate(`/budget-checks/`);
    }

    return (
        <div className="container">
            <div className="row">
                <div className="col">
                    <h1 className="mt-5 text-center">{t('budget-check-process-main-title')}</h1>
                    {!loading && (
                        <h3 className="mt-3 text-center">{budgetCheck.month + 1} / {budgetCheck.year}</h3>
                    )}
                    <hr/>
                </div>
            </div>
            {pageIndex === 0 ? (
                <div>
                    {loading ? (
                        <Loading/>
                    ) : (
                        budgetSections.map((section, index) => (
                            <TransactionSection
                                key={index}
                                title={section.title}
                                type={section.type}
                                transactions={section.transactions}
                                setBudgetCheckTransactions={setBudgetCheckTransactions}
                                t={t}
                            />
                        ))
                    )}
                    <div className="fixed-buttons-container">
                        <button
                            className="btn btn-secondary"
                            onClick={() => navigate(`/budget-checks/`)}
                        >
                            <i className="bi bi-arrow-left"/>
                        </button>
                        <button
                            className="btn btn-primary"
                            onClick={() => setPageIndex(pageIndex + 1)}
                        >
                            <i className="bi bi-arrow-right"/>
                        </button>
                    </div>
                </div>
            ) : (
                <>
                    <FinalView
                        budgetSections={budgetSections}
                        budgetCheck={budgetCheck}
                        setBudgetCheckScore={setBudgetCheckScore}
                        setBudgetCheckDeviation={setBudgetDeviation}
                    />
                    <div className="fixed-buttons-container">
                        <button
                            className="btn btn-secondary"
                            onClick={() => setPageIndex(pageIndex - 1)}
                        >
                            <i className="bi bi-arrow-left"/>
                        </button>
                        {!budgetCheck.closed && (
                            <button
                                className="btn btn-primary"
                                onClick={closeBudgetCheck}
                            >
                                <i className="bi bi-floppy"/>
                            </button>
                        )}
                    </div>
                </>
            )}
        </div>
    )
}

export default BudgetCheckProcess;