import {Tooltip} from "react-tooltip";
import React, {useState, useEffect} from "react";
import {getDataById} from "../../services/indexedDB";
import i18n from "./../../configurations/i18n";
import FormattedAmount from "./FormattedAmount";

function InputCurrency({ fieldKey, required, disabled, tooltip, label, isLinked, setLinkedCheckValue, linkedCheckValue, showCurrency, value, callback, monthlyRemainingIncome, annualRemainingIncome}) {
    const { t } = i18n;

    const [user, setUser] = useState(null);
    const [currency, setCurrency] = useState(null);
    const [currencyValue, setCurrencyValue] = useState(value || "");
    const [displayCurrency, setDisplayCurrency] = useState(true);

    const fetchUser = async () => {
        const user = await getDataById('user', 0);
        setUser(user);
    }

    useEffect(() => {
        if(showCurrency === true) {
            setDisplayCurrency(true);
        } else {
            setDisplayCurrency(false);
        }
    }, [showCurrency]);

    useEffect(() => {
        const fetchUserData = async () => {
            const user = await fetchUser();
            if (user) {
                setCurrency(user.currency || 'CHF');
            } else {
                setCurrency('CHF');
            }
        };

        fetchUserData();
        setCurrencyValue(value);
    }, [user, isLinked, value]);

    return (
        <div className="mb-3 currency-input">
            {label && (
                <label htmlFor={fieldKey} className="form-label">{t(label)}</label>
            )}
            {tooltip && (
                <>
                    <i className="bi bi-question-circle ms-1" data-tooltip-id={`tooltip-${fieldKey}`}></i>
                    <Tooltip id={`tooltip-${fieldKey}`} place="right" effect="solid">
                        {t(tooltip)}
                    </Tooltip>
                </>
            )}
            <div className="input-group">
                {displayCurrency && (
                <div className="input-group-text separate-line">{currency}</div>
                )}
                <input
                    type="number"
                    className="form-control separate-line"
                    id={fieldKey}
                    name={fieldKey}
                    value={currencyValue}
                    onChange={callback}
                    placeholder="0.00"
                    step="0.05"
                    required={required}
                    disabled={disabled}
                />
                {isLinked && (
                <div className="input-group-text">
                    <input
                        className="form-check-input mt-0"
                        name={`${fieldKey}-linked`}
                        type="checkbox"
                        checked={linkedCheckValue}
                        onChange={() => setLinkedCheckValue(!linkedCheckValue)}
                        aria-label="Checkbox for following text input"
                    />
                </div>
                )}
                {monthlyRemainingIncome && fieldKey === "monthly_position_size" && (
                    <span className="small text-danger fst-italic">{t('monthly-remaining-income')}: <FormattedAmount amount={monthlyRemainingIncome - currencyValue}/></span>
                )}
                {annualRemainingIncome && fieldKey === "annual_position_size" && (
                    <span className="small text-danger fst-italic">{t('annual-remaining-income')}: <FormattedAmount amount={annualRemainingIncome - currencyValue}/></span>
                )}
            </div>
        </div>
    );
}

export default InputCurrency;