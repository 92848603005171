import i18n from "./../../../configurations/i18n";

const t = i18n.t.bind(i18n);

const templates = [
  {
    title: t("template-name-one-person-income"),
    description: t("template-description-one-person-income"),
    type: "income",
    category: t("income"),
    monthly_position_size: null,
    annual_position_size: null,
    payable: [0, 1, 2, 3, 4, 5, 6, 7, 8, 9, 10, 11],
    icon: "money-bill-wave",
    user_tags: [t('income'), t('salary'), t('net')],
    pre_logged: true,
    monthly_spending_check: false
  },
  {
    title: t("template-name-bonus-income"),
    description: t("template-description-bonus-income"),
    type: "income",
    category: t("income"),
    monthly_position_size: 0,
    annual_position_size: null,
    payable: null,
    icon: "sack-dollar",
    user_tags: [t('income'), t('bonus'), t('net')],
    pre_logged: true,
    monthly_spending_check: false
  },
  {
    title: t("template-name-irregular-income"),
    description: t("template-description-irregular-income"),
    type: "income",
    category: t("income"),
    monthly_position_size: null,
    annual_position_size: null,
    payable: [0, 1, 2, 3, 4, 5, 6, 7, 8, 9, 10, 11],
    icon: "money-bill-trend-up",
    user_tags: [t('income'), t('irregular'), t('salary')],
    pre_logged: true,
    monthly_spending_check: false
  },
  {
    title: t("template-name-rent"),
    description: t("template-description-rent"),
    type: "expense",
    category: t("rent"),
    monthly_position_size: null,
    annual_position_size: null,
    payable: [0, 1, 2, 3, 4, 5, 6, 7, 8, 9, 10, 11],
    icon: "house",
    user_tags: [t('rent'), t('fixed-costs'), t('cold-rent')],
    pre_logged: true,
    monthly_spending_check: false
  },
  {
  title: t("template-name-rental-extra-costs"),
  description: t("template-description-rental-extra-costs"),
  type: "expense",
  category: t("rent"),
  monthly_position_size: null,
  annual_position_size: null,
  payable: null,
  icon: "temperature-three-quarters",
  user_tags: [t('rent'), t('extra-costs'), t('variable-costs'), t('water'), t('heating')],
  pre_logged: true,
  monthly_spending_check: false
},
{
  title: t("template-name-serafe-single-household"),
  description: t("template-description-serafe-single-household"),
  type: "expense",
  category: t("fees-and-taxes"),
  monthly_position_size: null,
  annual_position_size: null,
  payable: null,
  icon: "hand-holding-dollar",
  user_tags: [t('serafe'), t('state-fee'), t('media'), t('fixed-costs')],
  pre_logged: true,
  monthly_spending_check: false
},
{
  title: t("template-name-streaming-subscription"),
  description: t("template-description-streaming-subscription"),
  type: "expense",
  category: t("communication"),
  monthly_position_size: null,
  annual_position_size: null,
  payable: [0, 1, 2, 3, 4, 5, 6, 7, 8, 9, 10, 11],
  icon: "tv",
  user_tags: [t('streaming'), t('apps'), t('subscription'), t('media'), t('variable-costs')],
  pre_logged: true,
  monthly_spending_check: false
},
{
  title: t("template-name-electricity"),
  description: t("template-description-electricity"),
  type: "expense",
  category: t("rent"),
  monthly_position_size: null,
  annual_position_size: null,
  payable: null,
  icon: "plug",
  user_tags: [t('electricity'), t('power'), t('variable-costs')],
  pre_logged: true,
  monthly_spending_check: false
},
{
  title: t("template-name-mobile-internet-tv"),
  description: t("template-description-mobile-internet-tv"),
  type: "expense",
  category: t("communication"),
  monthly_position_size: null,
  annual_position_size: null,
  payable: [0, 1, 2, 3, 4, 5, 6, 7, 8, 9, 10, 11],
  icon: "globe",
  user_tags: [t('mobile'), t('internet'), t('tv'), t('connectivity'), t('fixed-costs')],
  pre_logged: true,
  monthly_spending_check: false
},
{
  title: t("template-name-cantonal-tax"),
  description: t("template-description-cantonal-tax"),
  type: "expense",
  category: t("fees-and-taxes"),
  monthly_position_size: null,
  annual_position_size: null,
  payable: null,
  icon: "hand-holding-dollar",
  user_tags: [t('cantonal'), t('tax'), t('provision'), t('fixed-costs')],
  pre_logged: true,
  monthly_spending_check: false
},
{
  title: t("template-name-governmental-tax"),
  description: t("template-description-governmental-tax"),
  type: "expense",
  category: t("fees-and-taxes"),
  monthly_position_size: null,
  annual_position_size: null,
  payable: null,
  icon: "hand-holding-dollar",
  user_tags: [t('tax'), t('state'), t('provision'), t('fixed-costs')],
  pre_logged: true,
  monthly_spending_check: false
},
{
  title: t("template-name-fire-department-tax"),
  description: t("template-description-fire-department-tax"),
  type: "expense",
  category: t("fees-and-taxes"),
  monthly_position_size: null,
  annual_position_size: null,
  payable: null,
  icon: "fire-extinguisher",
  user_tags: [t('tax'), t('fire-department'), t('provision')],
  pre_logged: true,
  monthly_spending_check: false
},
    {
        title: t("template-name-replacement-for-military-service"),
        description: t("template-description-replacement-for-military-service"),
        type: "expense",
        category: t("fees-and-taxes"),
        monthly_position_size: null,
        annual_position_size: null,
        payable: null,
        icon: "person-military-to-person",
        user_tags: [t('tax'), t('military'), t('provision'), t('replacement')],
        pre_logged: true,
        monthly_spending_check: false
    },
{
  title: t("template-name-health-insurance"),
  description: t("template-description-health-insurance"),
  type: "expense",
  category: t("insurance"),
  monthly_position_size: null,
  annual_position_size: null,
  payable: [0, 1, 2, 3, 4, 5, 6, 7, 8, 9, 10, 11],
  icon: "hand-holding-medical",
  user_tags: [t('fixed-costs'), t('health'), t('insurance')],
  pre_logged: true,
  monthly_spending_check: false
},
{
  title: t("template-name-additional-insurance"),
  description: t("template-description-additional-insurance"),
  type: "expense",
  category: t("insurance"),
  monthly_position_size: null,
  annual_position_size: null,
  payable: [0, 1, 2, 3, 4, 5, 6, 7, 8, 9, 10, 11],
  icon: "notes-medical",
  user_tags: [t('variable-costs'), t('insurance')],
  pre_logged: true,
  monthly_spending_check: false
},
{
  title: t("template-name-life-insurance"),
  description: t("template-description-life-insurance"),
  type: "expense",
  category: t("retirement-provisions"),
  monthly_position_size: null,
  annual_position_size: null,
  payable: [0, 1, 2, 3, 4, 5, 6, 7, 8, 9, 10, 11],
  icon: "person-cane",
  user_tags: [t('variable-costs'), t('insurance'), t('retirement'), t('self-employment'), t('house-ownership')],
  pre_logged: true,
  monthly_spending_check: false
},
{
  title: t("template-name-blocked-bank-savings"),
  description: t("template-description-blocked-bank-savings"),
  type: "expense",
  category: t("retirement-provisions"),
  monthly_position_size: null,
  annual_position_size: null,
  payable: [0, 1, 2, 3, 4, 5, 6, 7, 8, 9, 10, 11],
  icon: "person-cane",
  user_tags: [t('variable-costs'), t('bank-savings'), t('investment'), t('retirement'), t('self-employment'), t('house-ownership')],
  pre_logged: true,
  monthly_spending_check: false
},
{
  title: t("template-name-liability-insurance"),
  description: t("template-description-liability-insurance"),
  type: "expense",
  category: t("insurance"),
  monthly_position_size: null,
  annual_position_size: null,
  payable: null,
  icon: "ghost",
  user_tags: [t('fixed-costs'), t('insurance'), t('liability')],
  pre_logged: true,
  monthly_spending_check: false
},
{
  title: t("template-name-legal-protection-insurance"),
  description: t("template-description-legal-protection-insurance"),
  type: "expense",
  category: t("insurance"),
  monthly_position_size: null,
  annual_position_size: null,
  payable: [0, 1, 2, 3, 4, 5, 6, 7, 8, 9, 10, 11],
  icon: "scale-balanced",
  user_tags: [t('variable-costs'), t('insurance'), t('legal'), t('protection')],
  pre_logged: true,
  monthly_spending_check: false
},
{
  title: t("template-name-public-transport-subscription"),
  description: t("template-description-public-transport-subscription"),
  type: "expense",
  category: t("transportation"),
  monthly_position_size: null,
  annual_position_size: null,
  payable: [0, 1, 2, 3, 4, 5, 6, 7, 8, 9, 10, 11],
  icon: "train",
  user_tags: [t('variable-costs'), t('transportation'), t('public-transport')],
  pre_logged: true,
  monthly_spending_check: false
},
    {
    title: t("template-name-public-transport-tickets"),
    description: t("template-description-public-transport-tickets"),
    type: "expense",
    category: t("transportation"),
    monthly_position_size: null,
    annual_position_size: null,
    payable: [0, 1, 2, 3, 4, 5, 6, 7, 8, 9, 10, 11],
    icon: "bus",
    user_tags: [t('variable-costs'), t('tickets'), t('public-transport')],
    pre_logged: true,
    monthly_spending_check: true
},
{
    title: t("template-name-bike"),
    description: t("template-description-bike"),
    type: "expense",
    category: t("transportation"),
    monthly_position_size: null,
    annual_position_size: null,
    payable: [0, 1, 2, 3, 4, 5, 6, 7, 8, 9, 10, 11],
    icon: "bicycle",
    user_tags: [t('variable-costs'), t('bike'), t('personal-transport')],
    pre_logged: true,
    monthly_spending_check: false
},
{
    title: t("template-name-e-bike"),
    description: t("template-description-e-bike"),
    type: "expense",
    category: t("transportation"),
    monthly_position_size: null,
    annual_position_size: null,
    payable: [0, 1, 2, 3, 4, 5, 6, 7, 8, 9, 10, 11],
    icon: "bicycle",
    user_tags: [t('variable-costs'), t('e-bike'), t('personal-transport')],
    pre_logged: true,
    monthly_spending_check: false
},
{
    title: t("template-name-car"),
    description: t("template-description-car"),
    type: "expense",
    category: t("personal-transport"),
    monthly_position_size: null,
    annual_position_size: null,
    payable: [0, 1, 2, 3, 4, 5, 6, 7, 8, 9, 10, 11],
    icon: "car-side",
    user_tags: [t('variable-costs'), t('leasing'), t('personal-transport'), t('fuel'), t('insurance'), t('maintenance')],
    pre_logged: true,
    monthly_spending_check: false
},
{
    title: t("template-name-motorcycle"),
    description: t("template-description-motorcycle"),
    type: "expense",
    category: t("personal-transport"),
    monthly_position_size: null,
    annual_position_size: null,
    payable: [0, 1, 2, 3, 4, 5, 6, 7, 8, 9, 10, 11],
    icon: "motorcycle",
    user_tags: [t('variable-costs'), t('leasing'), t('personal-transport'), t('fuel'), t('insurance'), t('maintenance')],
    pre_logged: true,
    monthly_spending_check: false
},
{
    title: t("template-name-moto-tax"),
    description: t("template-description-moto-tax"),
    type: "expense",
    category: t("personal-transport"),
    monthly_position_size: null,
    annual_position_size: null,
    payable: [0, 1, 2, 3, 4, 5, 6, 7, 8, 9, 10, 11],
    icon: "hand-holding-dollar",
    user_tags: [t('variable-costs'), t('tax'), t('personal-transport')],
    pre_logged: true,
    monthly_spending_check: false
},
{
    title: t("template-name-Fuel"),
    description: t("template-description-Fuel"),
    type: "expense",
    category: t("personal-transport"),
    monthly_position_size: null,
    annual_position_size: null,
    payable: [0, 1, 2, 3, 4, 5, 6, 7, 8, 9, 10, 11],
    icon: "gas-pump",
    user_tags: [t('variable-costs'), t('fuel'), t('personal-transport')],
    pre_logged: true,
    monthly_spending_check: true
},
{
    title: t("template-name-moto-insurance"),
    description: t("template-description-moto-insurance"),
    type: "expense",
    category: t("personal-transport"),
    monthly_position_size: null,
    annual_position_size: null,
    payable: [0, 1, 2, 3, 4, 5, 6, 7, 8, 9, 10, 11],
    icon: "car-burst",
    user_tags: [t('variable-costs'), t('insurance'), t('personal-transport')],
    pre_logged: true,
    monthly_spending_check: false
},
{
    title: t("template-name-moto-maintenance"),
    description: t("template-description-moto-maintenance"),
    type: "expense",
    category: t("personal-transport"),
    monthly_position_size: null,
    annual_position_size: null,
    payable: [0, 1, 2, 3, 4, 5, 6, 7, 8, 9, 10, 11],
    icon: "screwdriver-wrench",
    user_tags: [t('variable-costs'), t('maintenance'), t('personal-transport')],
    pre_logged: true,
    monthly_spending_check: true
},
{
    title: t("template-name-moto-fees"),
    description: t("template-description-moto-fees"),
    type: "expense",
    category: t("personal-transport"),
    monthly_position_size: null,
    annual_position_size: null,
    payable: [0, 1, 2, 3, 4, 5, 6, 7, 8, 9, 10, 11],
    icon: "hand-holding-dollar",
    user_tags: [t('variable-costs'), t('fees'), t('personal-transport')],
    pre_logged: true,
    monthly_spending_check: false
},
{
    title: t("template-name-moto-amortisation"),
    description: t("template-description-moto-amortisation"),
    type: "expense",
    category: t("personal-transport"),
    monthly_position_size: null,
    annual_position_size: null,
    payable: [0, 1, 2, 3, 4, 5, 6, 7, 8, 9, 10, 11],
    icon: "receipt",
    user_tags: [t('variable-costs'), t('amortisation'), t('personal-transport')],
    pre_logged: true,
    monthly_spending_check: false
},
{
    title: t("template-name-moto-leasing"),
    description: t("template-description-moto-leasing"),
    type: "expense",
    category: t("personal-transport"),
    monthly_position_size: null,
    annual_position_size: null,
    payable: [0, 1, 2, 3, 4, 5, 6, 7, 8, 9, 10, 11],
    icon: "file-contract",
    user_tags: [t('variable-costs'), t('leasing'), t('personal-transport')],
    pre_logged: true,
    monthly_spending_check: false
},
{
    title: t("template-name-media-subscriptions"),
    description: t("template-description-media-subscriptions"),
    type: "expense",
    category: t("communication"),
    monthly_position_size: null,
    annual_position_size: null,
    payable: [0, 1, 2, 3, 4, 5, 6, 7, 8, 9, 10, 11],
    icon: "music",
    user_tags: [t('variable-costs'), t('media'), t('subscription'), t('apps')],
    pre_logged: true,
    monthly_spending_check: false
},
{
    title: t("template-name-association-contributions"),
    description: t("template-description-association-contributions"),
    type: "expense",
    category: t("various"),
    monthly_position_size: null,
    annual_position_size: null,
    payable: [0, 1, 2, 3, 4, 5, 6, 7, 8, 9, 10, 11],
    icon: "circle-dollar-to-slot",
    user_tags: [t('variable-costs'), t('association'), t('contributions')],
    pre_logged: true,
    monthly_spending_check: false
},
{
    title: t("template-name-membership-contributions"),
    description: t("template-description-membership-contributions"),
    type: "expense",
    category: t("various"),
    monthly_position_size: null,
    annual_position_size: null,
    payable: [0, 1, 2, 3, 4, 5, 6, 7, 8, 9, 10, 11],
    icon: "circle-dollar-to-slot",
    user_tags: [t('variable-costs'), t('membership'), t('contributions')],
    pre_logged: true,
    monthly_spending_check: false
},
{
    title: t("template-name-school-money"),
    description: t("template-description-school-money"),
    type: "expense",
    category: t("various"),
    monthly_position_size: null,
    annual_position_size: null,
    payable: [0, 1, 2, 3, 4, 5, 6, 7, 8, 9, 10, 11],
    icon: "school",
    user_tags: [t('variable-costs'), t('school'), t('money')],
    pre_logged: true,
    monthly_spending_check: false
},
{
    title: t("template-name-continuous-education-costs"),
    description: t("template-description-continuous-education-costs"),
    type: "expense",
    category: t("various"),
    monthly_position_size: null,
    annual_position_size: null,
    payable: [0, 1, 2, 3, 4, 5, 6, 7, 8, 9, 10, 11],
    icon: "graduation-cap",
    user_tags: [t('variable-costs'), t('continuous-education'), t('education')],
    pre_logged: true,
    monthly_spending_check: false
},
{
    title: t("template-name-hobby"),
    description: t("template-description-hobby"),
    type: "expense",
    category: t("personal-expenses"),
    monthly_position_size: null,
    annual_position_size: null,
    payable: [0, 1, 2, 3, 4, 5, 6, 7, 8, 9, 10, 11],
    icon: "futbol",
    user_tags: [t('variable-costs'), t('hobby'), t('personal-expenses')],
    pre_logged: true,
    monthly_spending_check: true
},
    {
    title: t("template-name-child-care"),
    description: t("template-description-child-care"),
    type: "expense",
    category: t("child-costs"),
    monthly_position_size: null,
    annual_position_size: null,
    payable: [0, 1, 2, 3, 4, 5, 6, 7, 8, 9, 10, 11],
    icon: "child",
    user_tags: [t('variable-costs'), t('child-care'), t('child-costs')],
    pre_logged: true,
    monthly_spending_check: false
},
{
    title: t("template-name-dept-yield-costs"),
    description: t("template-description-dept-yield-costs"),
    type: "expense",
    category: t("dept"),
    monthly_position_size: null,
    annual_position_size: null,
    payable: [0, 1, 2, 3, 4, 5, 6, 7, 8, 9, 10, 11],
    icon: "calendar",
    user_tags: [t('variable-costs'), t('yield'), t('costs'), t('dept')],
    pre_logged: true,
    monthly_spending_check: true
},
{
    title: t("template-name-dept-down-payments"),
    description: t("template-description-dept-down-payments"),
    type: "expense",
    category: t("dept"),
    monthly_position_size: null,
    annual_position_size: null,
    payable: [0, 1, 2, 3, 4, 5, 6, 7, 8, 9, 10, 11],
    icon: "file-invoice",
    user_tags: [t('variable-costs'), t('down-payments'), t('amortization'), t('dept')],
    pre_logged: true,
    monthly_spending_check: true
},
{
    title: t("template-name-alimony-payments"),
    description: t("template-description-alimony-payments"),
    type: "expense",
    category: t("child-costs"),
    monthly_position_size: null,
    annual_position_size: null,
    payable: [0, 1, 2, 3, 4, 5, 6, 7, 8, 9, 10, 11],
    icon: "child",
    user_tags: [t('variable-costs'), t('alimony'), t('payments'), t('child-costs')],
    pre_logged: true,
    monthly_spending_check: false
},
{
    title: t("template-name-household-costs"),
    description: t("template-description-household-costs"),
    type: "expense",
    category: t("household"),
    monthly_position_size: null,
    annual_position_size: null,
    payable: [0, 1, 2, 3, 4, 5, 6, 7, 8, 9, 10, 11],
    icon: "kitchen-set",
    user_tags: [t('variable-costs'), t('household'), t('costs'), t('daily-expenses')],
    pre_logged: true,
    monthly_spending_check: true
},
{
    title: t("template-name-food-drinks"),
    description: t("template-description-food-drinks"),
    type: "expense",
    category: t("household"),
    monthly_position_size: null,
    annual_position_size: null,
    payable: [0, 1, 2, 3, 4, 5, 6, 7, 8, 9, 10, 11],
    icon: "pizza-slice",
    user_tags: [t('variable-costs'), t('food'), t('groceries'), t('drinks'), t('daily-expenses')],
    pre_logged: true,
    monthly_spending_check: true
},
{
    title: t("template-name-guests"),
    description: t("template-description-guests"),
    type: "expense",
    category: t("personal-expenses"),
    monthly_position_size: null,
    annual_position_size: null,
    payable: [0, 1, 2, 3, 4, 5, 6, 7, 8, 9, 10, 11],
    icon: "people-line",
    user_tags: [t('variable-costs'), t('guests'), t('personal-expenses')],
    pre_logged: true,
    monthly_spending_check: true
},
{
    title: t("template-name-alcoholic-drinks"),
    description: t("template-description-alcoholic-drinks"),
    type: "expense",
    category: t("household"),
    monthly_position_size: null,
    annual_position_size: null,
    payable: [0, 1, 2, 3, 4, 5, 6, 7, 8, 9, 10, 11],
    icon: "martini-glass",
    user_tags: [t('variable-costs'), t('alcoholic-drinks'), t('going-out'), t('daily-expenses')],
    pre_logged: true,
    monthly_spending_check: true
},
    {
        title: t("template-name-pet"),
        description: t("template-description-pet"),
        type: "expense",
        category: t("pet"),
        monthly_position_size: null,
        annual_position_size: null,
        payable: [0, 1, 2, 3, 4, 5, 6, 7, 8, 9, 10, 11],
        icon: "dog",
        user_tags: [t('variable-costs'), t('pet'), t('pet-food'), t('pet-care')],
        pre_logged: true,
        monthly_spending_check: false
    },
    {
        title: t("template-name-pet-insurance"),
        description: t("template-description-pet-insurance"),
        type: "expense",
        category: t("pet"),
        monthly_position_size: null,
        annual_position_size: null,
        payable: [0, 1, 2, 3, 4, 5, 6, 7, 8, 9, 10, 11],
        icon: "hand-holding-medical",
        user_tags: [t('variable-costs'), t('pet'), t('insurance')],
        pre_logged: true,
        monthly_spending_check: false
    },
{
    title: t("template-name-clothes"),
    description: t("template-description-clothes"),
    type: "expense",
    category: t("personal-expenses"),
    monthly_position_size: null,
    annual_position_size: null,
    payable: [0, 1, 2, 3, 4, 5, 6, 7, 8, 9, 10, 11],
    icon: "shirt",
    user_tags: [t('variable-costs'), t('clothes'), t('personal-expenses')],
    pre_logged: true,
    monthly_spending_check: true
},
{
    title: t("template-name-pocket-money"),
    description: t("template-description-pocket-money"),
    type: "expense",
    category: t("personal-expenses"),
    monthly_position_size: null,
    annual_position_size: null,
    payable: [0, 1, 2, 3, 4, 5, 6, 7, 8, 9, 10, 11],
    icon: "wallet",
    user_tags: [t('variable-costs'), t('pocket-money'), t('personal-expenses'), t('daily-expenses')],
    pre_logged: true,
    monthly_spending_check: true
},
{
    title: t("template-name-hairdresser"),
    description: t("template-description-hairdresser"),
    type: "expense",
    category: t("personal-expenses"),
    monthly_position_size: null,
    annual_position_size: null,
    payable: [0, 1, 2, 3, 4, 5, 6, 7, 8, 9, 10, 11],
    icon: "scissors",
    user_tags: [t('variable-costs'), t('hairdresser'), t('personal-expenses'), t('styling')],
    pre_logged: true,
    monthly_spending_check: true
},
{
    title: t("template-name-eating-out"),
    description: t("template-description-eating-out"),
    type: "expense",
    category: t("personal-expenses"),
    monthly_position_size: null,
    annual_position_size: null,
    payable: [0, 1, 2, 3, 4, 5, 6, 7, 8, 9, 10, 11],
    icon: "utensils",
    user_tags: [t('variable-costs'), t('eating-out'), t('personal-expenses')],
    pre_logged: true,
    monthly_spending_check: true
},
{
    title: t("template-name-going-out"),
    description: t("template-description-going-out"),
    type: "expense",
    category: t("personal-expenses"),
    monthly_position_size: null,
    annual_position_size: null,
    payable: [0, 1, 2, 3, 4, 5, 6, 7, 8, 9, 10, 11],
    icon: "champagne-glasses",
    user_tags: [t('variable-costs'), t('going-out'), t('party'), t('bars'), t('personal-expenses')],
    pre_logged: true,
    monthly_spending_check: true
},
{
    title: t("template-name-provisions"),
    description: t("template-description-provisions"),
    type: "expense",
    category: t("provisions"),
    monthly_position_size: null,
    annual_position_size: null,
    payable: null,
    icon: "money-bill-transfer",
    user_tags: [t('variable-costs'), t('provisions')],
    pre_logged: true,
    monthly_spending_check: false
},
{
    title: t("template-name-eye-check-up"),
    description: t("template-description-eye-check-up"),
    type: "expense",
    category: t("health"),
    monthly_position_size: null,
    annual_position_size: null,
    payable: [0, 1, 2, 3, 4, 5, 6, 7, 8, 9, 10, 11],
    icon: "eye",
    user_tags: [t('variable-costs'), t('eyes'), t('check-up'), t('doctor'), t('health')],
    pre_logged: true,
    monthly_spending_check: false
},
{
    title: t("template-name-teeth-check-up"),
    description: t("template-description-teeth-check-up"),
    type: "expense",
    category: t("health"),
    monthly_position_size: null,
    annual_position_size: null,
    payable: [0, 1, 2, 3, 4, 5, 6, 7, 8, 9, 10, 11],
    icon: "tooth",
    user_tags: [t('variable-costs'), t('teeth'), t('check-up'), t('doctor'), t('health')],
    pre_logged: true,
    monthly_spending_check: false
},
{
    title: t("template-name-therapy"),
    description: t("template-description-therapy"),
    type: "expense",
    category: t("health"),
    monthly_position_size: null,
    annual_position_size: null,
    payable: [0, 1, 2, 3, 4, 5, 6, 7, 8, 9, 10, 11],
    icon: "user-doctor",
    user_tags: [t('variable-costs'), t('therapy'), t('doctor'), t('health')],
    pre_logged: true,
    monthly_spending_check: false
},
{
    title: t("template-name-veterinarian"),
    description: t("template-description-veterinarian"),
    type: "expense",
    category: t("pet"),
    monthly_position_size: null,
    annual_position_size: null,
    payable: [0, 1, 2, 3, 4, 5, 6, 7, 8, 9, 10, 11],
    icon: "syringe",
    user_tags: [t('variable-costs'), t('veterinarian'), t('pet'), t('health')],
    pre_logged: true,
    monthly_spending_check: false
},
{
    title: t("template-name-presents"),
    description: t("template-description-presents"),
    type: "expense",
    category: t("personal-expenses"),
    monthly_position_size: null,
    annual_position_size: null,
    payable: [0, 1, 2, 3, 4, 5, 6, 7, 8, 9, 10, 11],
    icon: "gift",
    user_tags: [t('variable-costs'), t('presents'), t('personal-expenses')],
    pre_logged: true,
    monthly_spending_check: false
},
{
    title: t("template-name-donations"),
    description: t("template-description-donations"),
    type: "expense",
    category: t("donations"),
    monthly_position_size: null,
    annual_position_size: null,
    payable: [0, 1, 2, 3, 4, 5, 6, 7, 8, 9, 10, 11],
    icon: "circle-dollar-to-slot",
    user_tags: [t('variable-costs'), t('donations')],
    pre_logged: true,
    monthly_spending_check: false
},
{
    title: t("template-name-school-camps"),
    description: t("template-description-school-camps"),
    type: "expense",
    category: t("child-costs"),
    monthly_position_size: null,
    annual_position_size: null,
    payable: [0, 1, 2, 3, 4, 5, 6, 7, 8, 9, 10, 11],
    icon: "campground",
    user_tags: [t('variable-costs'), t('school'), t('camps'), t('child-costs')],
    pre_logged: true,
    monthly_spending_check: false
},
{
    title: t("template-name-school-costs"),
    description: t("template-description-school-costs"),
    type: "expense",
    category: t("child-costs"),
    monthly_position_size: null,
    annual_position_size: null,
    payable: [0, 1, 2, 3, 4, 5, 6, 7, 8, 9, 10, 11],
    icon: "school",
    user_tags: [t('variable-costs'), t('school'), t('costs'), t('child-costs')],
    pre_logged: true,
    monthly_spending_check: false
},
{
    title: t("template-name-vacation"),
    description: t("template-description-vacation"),
    type: t("savings"),
    category: t("cash-savings"),
    monthly_position_size: null,
    annual_position_size: null,
    payable: [0, 1, 2, 3, 4, 5, 6, 7, 8, 9, 10, 11],
    icon: "plane-departure",
    user_tags: [t('variable-costs'), t('vacation'), t('savings')],
    pre_logged: true,
    monthly_spending_check: false
},
{
    title: t("template-name-cash-savings"),
    description: t("template-description-cash-savings"),
    type: "savings",
    category: t("cash-savings"),
    monthly_position_size: null,
    annual_position_size: null,
    payable: [0, 1, 2, 3, 4, 5, 6, 7, 8, 9, 10, 11],
    icon: "piggy-bank",
    user_tags: [t('variable-costs'), t('cash-savings'), t('savings')],
    pre_logged: true,
    monthly_spending_check: false
},
    {
        title: t("template-name-house-heating"),
        description: t("template-description-house-heating"),
        type: "expense",
        category: t("house-owner"),
        monthly_position_size: null,
        annual_position_size: null,
        payable: [0, 1, 2, 3, 4, 5, 6, 7, 8, 9, 10, 11],
        icon: "temperature-three-quarters",
        user_tags: [t('variable-costs'), t('heating'), t('house-owner')],
        pre_logged: true,
        monthly_spending_check: false
    },
    {
        title: t("template-name-house-tax"),
        description: t("template-description-house-tax"),
        type: "expense",
        category: t("house-owner"),
        monthly_position_size: null,
        annual_position_size: null,
        payable: [0, 1, 2, 3, 4, 5, 6, 7, 8, 9, 10, 11],
        icon: "hand-holding-dollar",
        user_tags: [t('variable-costs'), t('tax'), t('house-owner')],
        pre_logged: true,
        monthly_spending_check: false
    },
    {
        title: t("template-name-house-mortgage"),
        description: t("template-description-house-mortgage"),
        type: "expense",
        category: t("house-owner"),
        monthly_position_size: null,
        annual_position_size: null,
        payable: [0, 1, 2, 3, 4, 5, 6, 7, 8, 9, 10, 11],
        icon: "file-contract",
        user_tags: [t('variable-costs'), t('mortgage'), t('house-owner')],
        pre_logged: true,
        monthly_spending_check: false
    },
    {
        title: t("template-name-house-insurance"),
        description: t("template-description-house-insurance"),
        type: "expense",
        category: t("house-owner"),
        monthly_position_size: null,
        annual_position_size: null,
        payable: [0, 1, 2, 3, 4, 5, 6, 7, 8, 9, 10, 11],
        icon: "house-chimney-crack",
        user_tags: [t('variable-costs'), t('insurance'), t('house-owner')],
        pre_logged: true,
        monthly_spending_check: false
    },
    {
        title: t("template-name-house-electricity"),
        description: t("template-description-house-electricity"),
        type: "expense",
        category: t("house-owner"),
        monthly_position_size: null,
        annual_position_size: null,
        payable: [0, 1, 2, 3, 4, 5, 6, 7, 8, 9, 10, 11],
        icon: "plug",
        user_tags: [t('variable-costs'), t('electricity'), t('house-owner')],
        pre_logged: true,
        monthly_spending_check: false
    }

];

export default templates;