import React, { useEffect, useState, useRef } from "react";
import {addDataByKey, getAllData, deleteDataById, deleteDataByKey} from "../../services/indexedDB";
import YearMonthSelectionSlider from "../../components/logical-ui-elements/YearMonthSelectionSlider";
import Loading from "../../components/generic-view-components/loading/Loading";
import budgetCheckConfig from "./budgetCheckConfig";
import i18n from "./../../configurations/i18n";
import BudgetScore from "../../components/logical-ui-elements/BudgetScore";
import FormattedAmount from "../../components/logical-ui-elements/FormattedAmount";
import { useNavigate } from "react-router-dom";
import FormatDate from "../../components/logical-ui-elements/FormatDate";
import EncryptionService from "../../services/EncryptionService";

function BudgetChecks() {
    const [loading, setLoading] = useState(true);
    const { t } = i18n;
    const navigate = useNavigate();

    const [currentYear, setCurrentYear] = useState(new Date().getFullYear());
    const [currentMonth, setCurrentMonth] = useState(new Date().getMonth());

    const budgetCheckFieldConfig = budgetCheckConfig.budgetCheckDefaultValues;
    const budgetCheckTransactionRecordsConfig = budgetCheckConfig.budgetCheckTransactionRecords;
    const today = new Date();
    const isPastOrCurrent = currentYear < today.getFullYear() || (currentYear === today.getFullYear() && currentMonth <= today.getMonth());

    const [budgetCheckArray, setBudgetCheckArray] = useState([]);
    const [confirmDelete, setConfirmDelete] = useState(null);
    const confirmDeleteRef = useRef(null);

    const fetchBudgetCheckPositions = async () => {
        const fetchedData = await getAllData('budget-checks');
        const allData = fetchedData.values || [];
        const relevantData = allData.filter(item => item.year === currentYear && item.month === currentMonth);
        const loadingInterval = setTimeout(() => {
            if (fetchedData) {
                setBudgetCheckArray(relevantData);
                setLoading(false);
                clearInterval(loadingInterval);
            }
        }, 1000);
    };

    useEffect(() => {
        setLoading(true);
        fetchBudgetCheckPositions();
    }, [currentMonth, currentYear]);

    const createBudgetCheckRecords = async (bcId) => {
        const budgetPositions = await getAllData('financial-positions');
        const budgetCheckStandardRecords = budgetPositions.values.map(item => {
            item.title = EncryptionService.decrypt(item.title, item.iv);
            item.monthly_position_size = EncryptionService.decrypt(item.monthly_position_size, item.iv);
            if (EncryptionService.checkIfKeyExists()) {
                const iv = EncryptionService.generateIv();
                item.title = EncryptionService.encrypt(item.title, iv);
                item.monthly_position_size = EncryptionService.encrypt(item.monthly_position_size.toString(), iv);
                item.iv = iv;
            }
            return {
                ...budgetCheckTransactionRecordsConfig,
                finPosId: item.id,
                budget_check_id: bcId,
                icon: item.icon,
                title: item.title,
                isChecked: item.pre_logged || false,
                transaction_type: 'regular',
                type: item.type,
                amount_paid: item.monthly_position_size,
                amount_planned: item.monthly_position_size,
                iv: item.iv
            };
        });

        const budgetCheckIrregularRecords = budgetPositions.values.filter(item => item.payable.length !== 12 && item.payable.includes(currentMonth)).map(item => {
            item.title = EncryptionService.decrypt(item.title, item.iv);
            item.annual_position_size = EncryptionService.decrypt(item.annual_position_size, item.iv);
            var calculatedAmount = item.annual_position_size / item.payable.length;
            item.annual_position_size = calculatedAmount;
            if (EncryptionService.checkIfKeyExists()) {
                const iv = EncryptionService.generateIv();
                item.title = EncryptionService.encrypt(item.title, iv);
                item.annual_position_size = EncryptionService.encrypt(item.annual_position_size.toString(), iv);
                console.log(item.annual_position_size);
                item.iv = iv;
            }
            return {
                ...budgetCheckTransactionRecordsConfig,
                finPosId: item.id,
                budget_check_id: bcId,
                icon: item.icon,
                title: item.title,
                transaction_type: 'irregular',
                isChecked: false,
                payable: item.payable,
                type: item.type,
                amount_paid: item.annual_position_size,
                amount_planned: item.annual_position_size,
                iv: item.iv
            };
        });

        const allRecords = [...budgetCheckStandardRecords, ...budgetCheckIrregularRecords];

        for(const record of allRecords) {
            await addDataByKey('transactions', record);
        }
    }

    const createBudgetCheck = async () => {
        const data = {
            ...budgetCheckFieldConfig,
            year: currentYear,
            month: currentMonth,
            creation_date: new Date().toISOString(),
        }
        setLoading(true);
        await addDataByKey('budget-checks', data).then(
            (response) => createBudgetCheckRecords(response)
        ).finally(
            () => fetchBudgetCheckPositions()
        );
    }

    const goToBudgetCheck = (id) => {
        navigate('/budget-checks/details/' + id);
    }

    const handleDeleteClick = async (id) => {
        if (confirmDelete === id) {
            await deleteDataById('budget-checks', id);
            await deleteDataByKey('transactions', 'budget_check_id', id);
            setBudgetCheckArray(budgetCheckArray.filter(item => item.id !== id));
            setConfirmDelete(null);
        } else {
            setConfirmDelete(id);
        }
    }

    const handleClickOutside = (event) => {
        if (confirmDeleteRef.current && !confirmDeleteRef.current.contains(event.target)) {
            setConfirmDelete(null);
        }
    };

    useEffect(() => {
        document.addEventListener("mousedown", handleClickOutside);
        return () => {
            document.removeEventListener("mousedown", handleClickOutside);
        };
    }, []);

    return (
        <div className="container">
            <div className="row">
                <div className="col">
                    <h1 className="mt-5 text-center">Budget Checks</h1>
                    <hr />
                </div>
            </div>
            <div className="row">
                <div className="col">
                    <YearMonthSelectionSlider
                        setCurrentYear={setCurrentYear}
                        setCurrentMonth={setCurrentMonth}
                    />
                </div>
            </div>
            {loading ? (
                <Loading />
            ) : budgetCheckArray.length === 0 ? (
                <div className="row">
                    <div className="col">
                        {isPastOrCurrent ? (
                            <div className="text-center">
                                <h2 className="mt-5 text-center">You have not made any budget checks for this month</h2>
                                <button
                                    className="btn btn-primary mt-5"
                                    onClick={createBudgetCheck}
                                >
                                    {t('create-budget-check')}
                                </button>
                            </div>
                        ) : (
                            <div className="text-center">
                                <h2 className="mt-5 text-center">This budget check lies in the future, you cannot yet
                                    create it.</h2>
                            </div>
                        )}
                    </div>
                </div>
            ) : (
                <div className="row d-flex justify-content-center align-items-center">
                    {budgetCheckArray.length > 0 && budgetCheckArray.map((item, index) => (
                        <div key={index} className="col-12 col-md-6 col-lg-4">
                            <div className="card mt-4 p-0">
                                <div className="card-header d-flex justify-content-between align-items-center">
                                    <span>{item.month + 1} / {item.year}</span>
                                    {item.closed ? (
                                        <span><i className="bi bi-door-closed" /> {t('closed')}</span>
                                    ) : (
                                        <span><i className="bi bi-door-open" /> {t('open')}</span>
                                    )}
                                </div>
                                <div className="card-body text-center">
                                    <h2><BudgetScore score={item.score} /></h2>
                                    <h2><FormattedAmount amount={EncryptionService.decrypt(item.budget_deviation, item.iv)} /></h2>
                                </div>
                                <div className="card-footer justify-content-between align-items-center d-flex">
                                    <span className="small fst-italic">{t('created')}: <FormatDate dateString={item.creation_date} /></span>
                                    <span ref={confirmDeleteRef}>
                                        {confirmDelete === item.id ? (
                                                <button
                                                    className="btn btn-danger me-1" onClick={() => handleDeleteClick(item.id)}>Are you sure?</button>
                                            ) : (
                                                <button
                                                    className="btn btn-danger me-1" onClick={() => handleDeleteClick(item.id)}><i className="bi bi-trash" /></button>
                                            )
                                        }
                                        <button className="btn btn-primary ms-1" onClick={() => goToBudgetCheck(item.id)}>{item.closed ?
                                            <i className="bi bi-eye" /> : <i className="bi bi-pencil" />}</button>
                                    </span>
                                </div>
                            </div>
                        </div>
                    ))}
                </div>
            )}
        </div>
    );
}

export default BudgetChecks;