// frontend/src/services/EncryptionService.js
import CryptoJS from 'crypto-js';

const EncryptionService = {
    generateKey: (password, salt) => {
        const key = CryptoJS.PBKDF2(password, CryptoJS.enc.Hex.parse(salt), {
            keySize: 256 / 32, // 256-bit key
            iterations: 1000 // Number of iterations
        });
        return key.toString();
    },

    generateIv: () => {
        return CryptoJS.lib.WordArray.random(128 / 8).toString();
    },

    generateSalt: () => {
        return CryptoJS.lib.WordArray.random(128 / 8).toString();
    },

    checkIfKeyExists: () => {
        const userSession = JSON.parse(localStorage.getItem('userSession'));
        try {
            if (!userSession.token || userSession.token === "") {
                return false;
            }
            return true;
        } catch (error) {
            return false;
        }
    },

    encrypt: (data, iv = "", givenKey = "") => {
        var key;

        if(data === undefined) {
            return;
        }

        try {
            if(givenKey === ""){
                const userSession = JSON.parse(localStorage.getItem('userSession'));
                key = userSession.token;
            } else {
                key = givenKey;
            }
        } catch (error) {
            return data;
        }

        if (!key) {
            return data;
        }

        if(iv === "" || iv === undefined || iv === null) {
            return data;
        }

        const parsedKey = CryptoJS.enc.Hex.parse(key);

        var parsedIv = CryptoJS.enc.Hex.parse(iv);

        const encrypted = CryptoJS.AES.encrypt(data, parsedKey, {
            iv: parsedIv,
            mode: CryptoJS.mode.CBC,
            padding: CryptoJS.pad.Pkcs7
        });

        return encrypted.toString();
    },

    decrypt: (encryptedData, iv = "") => {
        var key = "";

        try {
            const userSession = JSON.parse(localStorage.getItem('userSession'));
            key = userSession.token;
        } catch (error) {
            return encryptedData;
        }


        if(iv === "" || iv === undefined || iv === null) {
            return encryptedData;
        }

        if(encryptedData === undefined || encryptedData === null) {
            return encryptedData;
        }

        if (!key || key === "" || key.length === 0) {
            return encryptedData;
            throw new Error('No secret key found in localStorage');
        }
        const parsedKey = CryptoJS.enc.Hex.parse(key);
        const parsedIv = CryptoJS.enc.Hex.parse(iv);

        const decrypted = CryptoJS.AES.decrypt(encryptedData, parsedKey, {
            iv: parsedIv,
            mode: CryptoJS.mode.CBC,
            padding: CryptoJS.pad.Pkcs7
        });

        return decrypted.toString(CryptoJS.enc.Utf8);
    }
};

export default EncryptionService;