import React, {useState, useEffect} from 'react';
import { useTranslation } from 'react-i18next';
import CreateEditForm from './../../../components/generic-view-components/create-edit-form/CreateEditForm';
import viewConfiguration from './../../../configurations/viewConfiguration.json';
import {useLocation} from "react-router-dom";
import {Link} from "react-router-dom";

function AddNewItem({ setChange }) {
    const { t } = useTranslation();
    const locationArray = useLocation();
    const { state } = locationArray;
    const { monthlyRemainingIncome, annualRemainingIncome } = state || {};

    const [viewConfig, setViewConfig] = useState([]);
    const [location, setLocation] = useState();

    useEffect(() => {
        const parts = locationArray.pathname.split('/');
        const conf_id = parts.length > 2 ? parts[1] : null;
        setViewConfig(viewConfiguration[conf_id]);
        setLocation(conf_id);
    }, [locationArray]);

    return (
        <div className="container">
            <div className="row">
                <div className="col-12">
                    <h1 className="text-center mt-5 mb-5">{t('add_new')} {t(location)}</h1>
                </div>
            </div>
            <div className="row">
                <div className="col-12">
                    <CreateEditForm
                        formFields={viewConfig.formFields}
                        initialValues={viewConfig.initialFormValues}
                        location={location}
                        onFormSubmit={() => window.history.back()}
                        setChange={setChange}
                        monthlyRemainingIncome={monthlyRemainingIncome}
                        annualRemainingIncome={annualRemainingIncome}
                    />
                </div>
            </div>
        </div>
    );
}

export default AddNewItem;