import React from 'react'
import {useState, useEffect} from 'react';
import {FontAwesomeIcon} from "@fortawesome/react-fontawesome";
import EncryptionService from "../../../services/EncryptionService";

function PosInfo({icon, title, addedInfo, color}) {

    const [positionIcon, setPositionIcon] = useState("calendar");
    const [positionTitle, setPositionTitle] = useState("");

    useEffect(() => {
        setPositionIcon(icon);
        setPositionTitle(title);
    }, [title, icon]);

    return (
        <div className="col-md-4 mb-4">
            <div className={`card h-100 bg-opacity-25 ${color}`}>
                <div className="card-body text-center mt-5 mb-5">
                    <h1 className="card-title display-1"><strong><FontAwesomeIcon
                        icon={positionIcon}/></strong>
                    </h1>
                    <h1 className="display-5">
                        {positionTitle}
                    </h1>
                    {addedInfo && (
                        <h2 className="card-text">
                            ({addedInfo})
                        </h2>
                    )}
                </div>
            </div>
        </div>
    )
}

export default PosInfo;