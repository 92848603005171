import React, { useEffect, useState } from 'react';
import { useNavigate, useLocation } from 'react-router-dom';
import templates from './budgetTemplates';
import { useTranslation } from 'react-i18next';
import './TemplatePicker.css';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { getDistinctValues } from '../../../services/dataService';
import ViewSearch from '../../logical-ui-elements/ViewSearch';
import InputCurrency from "../../logical-ui-elements/InputCurrency";
import InputMonthSelector from "../../logical-ui-elements/InputMonthSelector";
import {sortData} from "../../../services/dataService";

function TemplatePicker({ callback, setShowTemplatePicker, monthlyRemainingIncome, annualRemainingIncome }) {
    const { t } = useTranslation();
    const navigate = useNavigate();
    const locationArray = useLocation();

    const [location, setLocation] = useState();
    const [originalTemplates, setOriginalTemplates] = useState([]);
    const [displayedTemplates, setDisplayedTemplates] = useState([]);
    const [categories, setCategories] = useState([]);
    const [selectedTemplate, setSelectedTemplate] = useState(null);
    const [showTemplateInputs, setShowTemplateInputs] = useState(false);
    const [templateInputs, setTemplateInputs] = useState({});
    const [isLinkedValue, setIsLinkedValue] = useState(true);
    const [linkedConfig, setLinkedConfig] = useState(false);

    useEffect(() => {
        const parts = locationArray.pathname.split('/');
        const conf_id = parts.length > 2 ? parts[1] : null;
        setLocation(conf_id);
    }, [locationArray]);

    useEffect(() => {
        setOriginalTemplates(templates);
    }, []);

    useEffect(() => {
        const cats = getDistinctValues(originalTemplates, "category");
        setCategories(cats);
    }, [originalTemplates, displayedTemplates]);

    useEffect(() => {
        const oTemplates = sortData(originalTemplates, 'category', 'desc');
        setDisplayedTemplates(oTemplates);
    }, [originalTemplates]);

    const handleCardClick = (template) => {
        const nullFields = Object.keys(template).filter(key => template[key] === null );
        const inputs = {};
        nullFields.forEach(field => {
            if(field === 'monthly_position_size' || field === 'annual_position_size') {
                inputs[field] = '';
            }
            if(field === 'payable') {
                inputs[field] = [];
            }
        });
        setLinkedConfig(nullFields.includes('monthly_position_size') && nullFields.includes('annual_position_size'));
        setTemplateInputs(inputs);
        setSelectedTemplate(template);
        setShowTemplateInputs(true);
    };

    const handleInputChange = (e) => {
        const { name, value } = e.target;
        if(linkedConfig && name === 'monthly_position_size') {
            setTemplateInputs({
                ...templateInputs,
                [name]: value,
                annual_position_size: value * 12
            });
        } else if(linkedConfig && name === 'annual_position_size') {
            setTemplateInputs({
                ...templateInputs,
                [name]: value,
                monthly_position_size: value / 12
            });
        } else {
            setTemplateInputs({
                ...templateInputs,
                [name]: value
            });
        }
    };

    const handleMonthSelector = (name, value) => {
        setTemplateInputs((prev) => ({ ...prev, [name]: value }));
    }

    const handleConfirm = () => {
        const updatedTemplate = { ...selectedTemplate };
        Object.keys(templateInputs).forEach(key => {
            updatedTemplate[key] = templateInputs[key];
        });
        callback(updatedTemplate);
        setShowTemplatePicker(false);
        setShowTemplateInputs(false);
    };

    return (
        <div>
            {!showTemplateInputs ? (
                <>
                    <div className="row">
                        <div className="col">
                            <ViewSearch
                                initialValueList={originalTemplates}
                                setNewValueList={setDisplayedTemplates}
                            />
                        </div>
                    </div>
                    {categories.map((category, index) => (
                        displayedTemplates.filter(template => template.category === category).length > 0 && (
                            <div key={category + "_" + index}>
                                <h1 className="text-center mt-5 mb-5">{t(category)}</h1>
                                <div className="row">
                                    {displayedTemplates.filter(template => template.category === category).map((template, index) => (
                                        <div key={template + "_" + index} className="col-md-4 v-100">
                                            <div className="card m-3"
                                                 onClick={() => handleCardClick(template)}>
                                                <div className="card-body">
                                                    <h5 className="card-title"><FontAwesomeIcon
                                                        icon={template.icon}/> {template.title}</h5>
                                                    <p className="card-text">{template.description}</p>
                                                </div>
                                            </div>
                                        </div>
                                    ))}
                                </div>
                            </div>
                        )
                    ))}
                    <div className="fixed-buttons-container">
                        <button className="btn btn-secondary" onClick={() => setShowTemplatePicker(false)}><i
                            className="bi bi-arrow-return-left"/></button>
                    </div>
                </>
            ) : (
                <div>
                    <h2>{t('add-missing-values-to-template')}</h2>
                    {linkedConfig ? (
                        <>
                            <InputCurrency
                                fieldKey="monthly_position_size"
                                required={true}
                                label={t("template-question-type-monthly-amount")}
                                value={templateInputs['monthly_position_size']}
                                callback={handleInputChange}
                                isLinked={linkedConfig}
                                linkedCheckValue={isLinkedValue}
                                setLinkedCheckValue={setIsLinkedValue}
                                monthlyRemainingIncome={monthlyRemainingIncome}
                            />
                            <InputCurrency
                                fieldKey="annual_position_size"
                                required={true}
                                label={t("template-question-type-annual-amount")}
                                value={templateInputs['annual_position_size']}
                                isLinked={linkedConfig}
                                linkedCheckValue={isLinkedValue}
                                setLinkedCheckValue={setIsLinkedValue}
                                callback={handleInputChange}
                                annualRemainingIncome={annualRemainingIncome}
                            />
                        </>
                    ) : (
                        Object.keys(templateInputs).map((key) => {
                            if (key === 'monthly_position_size') {
                                return (
                                    <InputCurrency
                                        key={key}
                                        fieldKey="monthly_position_size"
                                        required={true}
                                        label={t("template-question-type-monthly-amount")}
                                        value={templateInputs['monthly_position_size']}
                                        callback={handleInputChange}
                                        monthlyRemainingIncome={monthlyRemainingIncome}
                                    />
                                );
                            }
                            if (key === 'annual_position_size') {
                                return (
                                    <InputCurrency
                                        key={key}
                                        fieldKey="annual_position_size"
                                        required={true}
                                        label={t("template-question-type-annual-amount")}
                                        value={templateInputs['annual_position_size']}
                                        callback={handleInputChange}
                                        annualRemainingIncome={annualRemainingIncome}
                                    />
                                );
                            }
                            if (key === 'payable') {
                                return (
                                    <InputMonthSelector
                                        key={key}
                                        fieldName={key}
                                        months={[]}
                                        onSelect={handleMonthSelector}
                                        label={t("template-question-type-monthly-payable")}
                                    />
                                );
                            }
                            return null;
                        })
                    )}
                    <div className="fixed-buttons-container">
                        <button className="btn btn-secondary" onClick={() => setShowTemplateInputs(false)}><i
                            className="bi bi-arrow-return-left"/></button>
                        <button className="btn btn-success" onClick={handleConfirm}><i className="bi bi-floppy"/></button>
                    </div>
                </div>
            )}
        </div>
    );
}

export default TemplatePicker;