import React from "react";
import MonthNameGiver from "../../../components/logical-ui-elements/MonthNameGiver";
import EncryptionService from "../../../services/EncryptionService";

function UserTagsInfo({label, user_tags, color, wIv}) {

    return (
        <div className="col-md-4 mb-4">
            <div className={`card h-100 bg-opacity-25 ${color}`}>
                <div className="card-body text-center mt-5 mb-5">
                    <h3>{label}</h3>
                    <h2>
                        {user_tags.length > 0 && (
                            user_tags.map((tag, index) => (
                                <span key={index} className="fw-light fst-italic">{EncryptionService.decrypt(tag, wIv)} </span>
                            ))
                        )}

                    </h2>
                </div>
            </div>
        </div>
    );
}

export default UserTagsInfo;