import Loading from "../../components/generic-view-components/loading/Loading";
import React, { useEffect, useState } from "react";
import { getAllData } from "../../services/indexedDB";
import LargestExpenseCard from "./dashboard-widgets/LargestExpenseCard";
import UpcomingBills from "./dashboard-widgets/UpcomingBills";
import WelcomeInfo from "./dashboard-widgets/WelcomeInfo";
import NetWealth from "./dashboard-widgets/NetWealth";
import UnallocatedBudget from "./dashboard-widgets/UnallocatedBudget";
import {SavingsRateCard} from "./dashboard-widgets/SavingsRateCard";
import {TopSpendingCategoriesCard} from "./dashboard-widgets/TopSpendingCategoriesCard";
import EncryptionService from "../../services/EncryptionService";

function Dashboard() {
    const [loading, setLoading] = useState(true);
    const [user, setUser] = useState(null);

    const getUser = async () => {
        const data = await getAllData('user');
        setUser(data.values[0]);
    };

    useEffect(() => {
        getUser();
        setTimeout(() => {
            if (user) {
                setLoading(false);
            } else {
                getUser();
            }
        }, 1000);
    }, [user]);

    if (loading) {
        return <Loading />;
    }

    return (
        <div className="container">
            <div className="row pt-5">
                {user && (
                    <WelcomeInfo
                        title={EncryptionService.decrypt(user.username, user.iv)}
                        addedInfo={new Date(user.last_save).toLocaleString()}
                        color="bg-primary"
                    />
                )}
                    <TopSpendingCategoriesCard />
                    <UpcomingBills />

                </div>
            </div>
            );
            }

            export default Dashboard;