import React, { useState, useEffect } from 'react';
import { getAllData } from "../../services/indexedDB";
import { Tooltip } from 'react-tooltip';
import EncryptionService from "../../services/EncryptionService";

function InputDatabaseDropdown({ apiEndpoint, value, valueLabel, valueLabelAddedInfo, onChange, name, label, disabled, tooltip }) {
    const [retreivedItems, setRetreivedItems] = useState([]);

    const getCategories = async () => {
        try {
            const data = await getAllData(apiEndpoint);
            console.log("data", data.values);
            setRetreivedItems(data.values);
        } catch (error) {
            console.error('Error fetching positions:', error);
        }
    }

    useEffect(() => {
        getCategories();
    }, []);

    const handleChange = (e) => {
        onChange(e, name);
    }

    if (retreivedItems.length === 0) {
        return (
            <div className="mb-3">
                <label htmlFor={`${name}Selector`} className="form-label">{label}</label>
                <input type="text" className="form-control" id={`${name}Selector`} disabled={true} value={`You need to create a ${label} before you can choose it here.`} />
            </div>
        )
    }

    return (
        <div className="mb-3">
            <label htmlFor={`${name}Selector`} className="form-label">{label}</label>
            <i className="bi bi-question-circle ms-1" data-tooltip-id={`tooltip-${name}`}></i>
            <Tooltip id={`tooltip-${name}`} place="top" effect="solid">
                {tooltip}
            </Tooltip>
            <select onChange={handleChange} id={`${name}Selector`} className="form-control"
                    value={value}
                    disabled={disabled}>
                <option value="">{`Choose ${label}`}</option>
                {retreivedItems.map((item, index) => (
                    <option key={index} value={item.id}>
                        {EncryptionService.decrypt(item[valueLabel], item.iv)} {item[valueLabelAddedInfo] ? `(${EncryptionService.decrypt(item[valueLabelAddedInfo], item.iv)})` : null}
                    </option>
                ))}
            </select>
        </div>
    );
}

export default InputDatabaseDropdown;