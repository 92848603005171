import React, { useState, useEffect } from 'react';
import {Link, useLocation} from 'react-router-dom';
import viewConfiguration from '../../configurations/viewConfiguration.json';
import Loading from '../../components/generic-view-components/loading/Loading';
import NoData from './no-data/NoData';
import TableView from './table/TableView';
import { useTranslation } from 'react-i18next';
import { getAllData } from '../../services/indexedDB';
import './MainMenuOverviewPage.css';
import {distinctValueSummarizer} from "../../services/dataService";

function MainMenuOverviewPage({ setChange }) {
    const { t } = useTranslation();
    const locationArray = useLocation();
    const [location, setLocation] = useState(locationArray.pathname.slice(1));
    const [originalPositions, setOriginalPositions] = useState([]);
    const [filteredList, setFilteredList] = useState([]);
    const [viewConfig, setViewConfig] = useState([]);
    const [loading, setLoading] = useState(true);
    const [addModeActive, setAddModeActive] = useState(false);
    const [isUseTemplateActive, setIsUseTemplateActive] = useState(false);
    const [viewModeActive, setViewModeActive] = useState(false);
    const [monthlyRemainingIncome, setMonthlyRemainingIncome] = useState(0);
    const [annualRemainingIncome, setAnnualRemainingIncome] = useState(0);

    useEffect(() => {
        const fetchPositions = async () => {
            try {
                const data = await getAllData(location);
                setOriginalPositions(data.values);
                setFilteredList(data.values);
                setLoading(false);
            } catch (error) {
                console.error('Error fetching positions:', error);
            }
        };
        if (location) {
            fetchPositions();
        }
    }, [location]);

    useEffect(() => {
        const sumOfMonthlyTypes = distinctValueSummarizer(originalPositions, 'type', 'monthly_position_size');
        const monthlyRemainingIncome = (sumOfMonthlyTypes['income'] || 0) - (sumOfMonthlyTypes['expense'] || 0) - (sumOfMonthlyTypes['savings'] || 0);
        setMonthlyRemainingIncome(monthlyRemainingIncome || 0);
    }, [originalPositions]);

    useEffect(() => {
        const sumOfAnnualTypes = distinctValueSummarizer(originalPositions, 'type', 'annual_position_size');
        const annualRemainingIncome = (sumOfAnnualTypes['income'] || 0) - (sumOfAnnualTypes['expense'] || 0) - (sumOfAnnualTypes['savings'] || 0);
        setAnnualRemainingIncome(annualRemainingIncome || 0);
    }, [originalPositions]);

    useEffect(() => {
        if(locationArray.pathname.slice(1) !== location) {
            setLoading(true);
        }
        setViewModeActive(false);
        setAddModeActive(false);
        setLocation(locationArray.pathname.slice(1));
    }, [locationArray]);

    useEffect(() => {
        setViewConfig(viewConfiguration[location]);
    }, [location]);

    if (loading) {
        return <Loading />;
    }

    if (originalPositions.length === 0) {
        return (
            <div className="container">
                    <div className="row">
                        <NoData pageContent={t(location)} location={location} setAddModeActive={setAddModeActive} />
                    </div>
            </div>
        );
    }

    return (
        <>
            <div className="container">
                        <div className="row">
                            <div className="col-12">
                                <h1 className="text-center mt-5 mb-2">{t(location)}</h1>
                            </div>
                        </div>
                        <div className="row">
                            <div className="col-12">
                                {filteredList.length === 0 && (
                                    <div className="text-center">
                                        <h2 className="no-search-results">{t('no_results')}</h2>
                                        <h4>{t('no_results_description')}</h4>
                                    </div>
                                )}
                                <TableView
                                    contentList={filteredList}
                                    listViewConfig={viewConfig.listViewConfig}
                                    listViewCustomOrder={viewConfig.listViewCustomOrder}
                                    setViewModeActive={setViewModeActive}
                                    viewModeActive={viewModeActive}
                                    listViewTitle={location}
                                    listViews={viewConfig.listViews}
                                    location={location}
                                    monthlyRemainingIncome={monthlyRemainingIncome}
                                    annualRemainingIncome={annualRemainingIncome}
                                />
                            </div>
                        </div>
                <div className="fixed-buttons-container">
                    <Link
                            to={`/${location}/add-new`}
                            className="btn btn-success"
                            state={{ monthlyRemainingIncome, annualRemainingIncome }}
                    >
                        <i className="bi bi-plus-lg"/>
                        </Link>
                </div>
            </div>
        </>
    );
}

export default MainMenuOverviewPage;