import React, { useEffect, useState } from "react";
import FormattedAmount from "../../../components/logical-ui-elements/FormattedAmount";
import { getAllData } from "../../../services/indexedDB";
import { useTranslation } from "react-i18next";
import {distinctValueSummarizer} from "../../../services/dataService";

function UnallocatedBudget() {
    const { t } = useTranslation();
    const [unallocatedAmount, setUnallocatedAmount] = useState(0);

    const calculateNetWealth = async () => {
        const data = await getAllData("financial-positions");
        const financial_positions = data.values;
        const result = distinctValueSummarizer(financial_positions, "type", "monthly_position_size");
    };

    useEffect(() => {
        calculateNetWealth();
    }, []);

    return (

            <div className="card">
                <div className="card-body">
                    Unallocated Budget: <FormattedAmount amount={unallocatedAmount} />
                </div>
            </div>
    );
}

export default UnallocatedBudget;