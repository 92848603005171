import React, { useEffect, useState } from 'react';
import { getAllData } from "../../services/indexedDB";
import {Tooltip} from "react-tooltip";
import EncryptionService from "../../services/EncryptionService";
import i18n from "../../configurations/i18n";

function InputDataList({ apiEndpoint, selectableValue, value, label, callback, fieldKey, tooltip, placeholder, required, disabled }) {

    const [retreivedItems, setRetreivedItems] = useState([]);
    const [selectableItems, setSelectableItems] = useState([]);
    const { t } = i18n;

    const getCategories = async () => {
        const allItems = await getAllData(apiEndpoint);
        setRetreivedItems(allItems.values);
    }

    useEffect(() => {
        getCategories();
    }, []);

    useEffect(() => {
        if (retreivedItems && retreivedItems.length > 0) {
            const uniqueItems = new Set(retreivedItems.map(item => EncryptionService.decrypt(item[selectableValue], item.iv)));
            setSelectableItems([...uniqueItems]);
        }
    }, [retreivedItems, selectableValue]);

    return (
        <div className="mb-3">
            {label && (
                <label htmlFor="categorySelection" className="form-label">{label}</label>
            )}
            {tooltip && (
                <>
                    <i className="bi bi-question-circle ms-1" data-tooltip-id={`tooltip-${fieldKey}`}></i>
                    <Tooltip id={`tooltip-${fieldKey}`} place="rght" effect="solid">
                        {t(tooltip)}
                    </Tooltip>
                </>
            )}
            <input className="form-control"
                   list="datalistOptions"
                   name={fieldKey}
                   id={fieldKey}
                   placeholder={t(placeholder)}
                   value={t(value)}
                   required={required}
                   onChange={callback}
                   disabled={disabled}
            />
            <datalist id="datalistOptions">
                {selectableItems.map((item, index) => (
                    <option key={index} value={item}/>
                ))}
            </datalist>
        </div>
    );
}

export default InputDataList;