import React, {useState, useEffect} from "react";
import { getAllData } from "../../../services/indexedDB";
import i18n from "./../../../configurations/i18n";
import {distinctValueSummarizer, removeArrayValues, sortData} from "../../../services/dataService";
import FormattedAmount from "../../../components/logical-ui-elements/FormattedAmount";

export const TopSpendingCategoriesCard = () => {

    const t = i18n.t.bind(i18n);

    const [data, setData] = useState([]);
    const [topCategories, setTopCategories] = useState([]);

    const getFinancialData = async () => {
        const data = await getAllData("financial-positions");
        return data.values;
    }

    useEffect(() => {
        const fetchData = async () => {
            const fetchedData = await getFinancialData();
            setData(fetchedData);
        };
        fetchData();
    }, []);

    useEffect(() => {
        if (data.length > 0) {
            const cleanedUpData = removeArrayValues(data, "type", "income");
            const sumData = distinctValueSummarizer(cleanedUpData, "category", "monthly_position_size");
            const sumDataArray = Object.entries(sumData);
            const sortedData = sortData(sumDataArray, 1, "desc");
            setTopCategories(sortedData.slice(0, 3));
        }
    }, [data]);

    return (
        <div className="col-md-4 mb-4">
            <div className={`card h-100 bg-opacity-50 bg-danger-subtle`}>
                <div className="card-body">
                    <h3 className="text-center mt-5">{t('dashboard-widget-top-spending-categories')}</h3>
                    <ol className="list-group no-border mb-5 bg-transparent">
                        {topCategories.map((category, index) => (
                            <li key={index} className="list-group-item d-flex justify-content-between align-items-start bg-transparent">
                                {category[0]} <FormattedAmount amount={category[1]}/>
                            </li>
                        ))}
                    </ol>
                </div>
            </div>
        </div>
    );
};