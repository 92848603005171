import React, { useEffect, useState, useRef } from 'react';
import FormattedAmount from "../../components/logical-ui-elements/FormattedAmount";
import i18n from 'i18next';
import BudgetScore from "../../components/logical-ui-elements/BudgetScore";
import Loading from "../../components/generic-view-components/loading/Loading";
import EncryptionService from "../../services/EncryptionService";

function FinalView({ budgetSections, setBudgetCheckDeviation, setBudgetCheckScore}) {
    const t = i18n.t.bind(i18n);
    const elementsRef = useRef([]);

    const [loading, setLoading] = useState(true);

    const [budgetDeviation, setBudgetDeviation] = useState(0);
    const [budgetScore, setBudgetScore] = useState(0);

    const calculateBudgetScore = (planned, effective) => {
        if (planned === 0) return 0; // Avoid division by zero

        if (planned === effective) return 5; // Perfect match

        const deviation = Math.abs(planned - effective) / planned;
        const score = 5 - (deviation * 5);

        return Math.max(0, Math.min(5, score)); // Ensure the score is between 0 and 5
    };

    useEffect(() => {
        if(budgetSections.length > 0) {
            const planned = budgetSections.reduce((acc, item) => acc + item.transactions.reduce((acc, item) => acc + parseFloat(EncryptionService.decrypt(item.amount_planned, item.iv)), 0), 0);
            const effective = budgetSections.reduce((acc, item) => acc + item.transactions.reduce((acc, item) => acc + parseFloat(EncryptionService.decrypt(item.amount_paid, item.iv)), 0), 0);
            const loadingInterval = setTimeout(() => {
                if (planned && effective) {
                    setBudgetDeviation(Number((planned - effective).toFixed(2)));
                    setBudgetScore(calculateBudgetScore(planned, effective));
                    setLoading(false);
                    clearInterval(loadingInterval);
                }
            }, 1000);
        }
    }, [budgetSections]);

    console.log(budgetDeviation)

    useEffect(() => {
        setBudgetCheckScore(budgetScore);
        setBudgetCheckDeviation(budgetDeviation);
    }, [budgetScore, budgetDeviation]);

    const determineBadgeColor = (planned, effective, type) => {
        if (planned === effective) {
            return 'bg-primary';
        }
        if (type === 'expense' && planned > effective) {
            return 'bg-success';
        }
        if (type === 'income' || type === 'savings' && planned > effective) {
            return 'bg-danger';
        }
        if (type === 'expense' && planned < effective) {
            return 'bg-danger';
        }
        if (type === 'income' || type === 'savings' && planned < effective) {
            return 'bg-success';
        }
        else {
            return 'bg-primary';
        }
    }

    return (
        loading ? (
                <Loading/>
        ) : (
        <div className="container">
            <div className="row text-center mt-5 mb-5">
                <div className="col-12 mt-5 mb-5 fade-in" ref={el => elementsRef.current[2] = el}>
                    <h3 className="">{t("budget-check-final-view-budget-score")}</h3>
                    <h2 className="display-1"><BudgetScore score={budgetScore}/></h2>
                </div>
            </div>
            <div className="row text-center mt-5 mb-5">
                <div className="col-12 mt-5 mb-5 fade-in" ref={el => elementsRef.current[1] = el}>
                    <h3>{t("budget-check-final-view-abbreviation-from-budget")}</h3>
                    <h2 className="display-1"><FormattedAmount amount={budgetDeviation}/></h2>
                    <div className="badges mb-4">
                    </div>
                </div>
            </div>
            {budgetSections.map((section, index) => (
                <div key={index} className="row text-center mt-5 mb-5">
                    <div className="col-12 mt-5 mb-5 fade-in" ref={el => elementsRef.current[3] = el}>
                        <h3>{t(section.title)}</h3>
                        <h2 className="display-1">
                            <FormattedAmount amount={section.transactions.reduce((acc, item) => acc + parseFloat(EncryptionService.decrypt(item.amount_paid, item.iv)), 0)}/>
                        </h2>
                        <div className="badges">
                            {section.transactions.map((position, index) => (
                                <span key={index} className={`badge ms-1 fst-italic ${determineBadgeColor(EncryptionService.decrypt(position.amount_planned, position.iv), EncryptionService.decrypt(position.amount_paid, position.iv), position.type)}`}>{EncryptionService.decrypt(position.title, position.iv)}</span>
                            ))}
                        </div>
                    </div>
                </div>
            ))}
        </div>
        )
    )
}

export default FinalView;