import React from 'react';
import {Tooltip} from "react-tooltip";
import i18n from "./../../configurations/i18n";

function InputTextField({ field, formValues, callback }) {
    const { t } = i18n;

    const handleChange = (e) => {
        callback(e);
    }

    return (
        <div className="mb-3">
            {field.label && (
            <label htmlFor={field.key} className="form-label">{t(field.label)}</label>
            )}
            {field.tooltip && (
                <>
                <i className="bi bi-question-circle ms-1" data-tooltip-id={`tooltip-${field.key}`}></i>
                <Tooltip id={`tooltip-${field.key}`} place="right" effect="solid">
                    {t(field.tooltip)}
                </Tooltip>
                </>
            )}
            <input
                type={field.fieldType}
                className="form-control"
                id={field.key}
                name={field.key}
                value={t(formValues[field.key]) || ''}
                onChange={handleChange}
                required={field.required}
                placeholder={t(field.placeholder)}
            />
        </div>
    );
}

export default InputTextField;