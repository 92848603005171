import React, { useState, useEffect } from 'react';
import { useLocation } from 'react-router-dom';
import { Table, FormControl, Button } from 'react-bootstrap';
import { useNavigate } from 'react-router-dom';
import { useTranslation } from 'react-i18next';
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import './TemplatePicker.css'; // Import the custom CSS file
import {addDataByKey, updateDataById} from "../../../services/indexedDB";
import EncryptionService from "../../../services/EncryptionService";
import InputCurrency from "../../logical-ui-elements/InputCurrency";

function TemplatePositions({setChange}) {
    const { t } = useTranslation();
    const location = useLocation();
    const navigate = useNavigate();
    const template = location.state?.template;
    const [positions, setPositions] = useState([]);
    const [templateQuestions, setTemplateQuestions] = useState([]);
    const [selectedPositions, setSelectedPositions] = useState([]);
    const [mainLocation, setMainLocation] = useState();
    const [linkedCheckValue, setLinkedCheckValue] = useState(false); // State to manage the linked checkbox

    useEffect(() => {
        if (template) {
            setPositions(template['template-positions'] || []);
            setTemplateQuestions(template['template-questions'] || []);
        }
    }, [template]);

    useEffect(() => {
        const parts = location.pathname.split('/');
        const conf_id = parts.length > 2 ? parts[1] : null;
        setMainLocation(conf_id);
    }, [location]);

    useEffect(() => {
        const selectedPositions = positions.filter(position => position.isChecked);
        setSelectedPositions(selectedPositions);
    }, [positions]);

    const handleInputChange = (index, field, value) => {
        const updatedPositions = [...positions];
        updatedPositions[index][field] = value;
        setPositions(updatedPositions);
    };

    const handleSubmit = () => {

        if(EncryptionService.checkIfKeyExists()) {
            var iv = EncryptionService.generateIv();
        }

        selectedPositions.forEach( async position => {

            const validPosition = {
                title: EncryptionService.encrypt(position.title, iv),
                type: template['template-type'],
                category: EncryptionService.encrypt(template['template-category'], iv),
                monthly_position_size: EncryptionService.encrypt(position.monthly_position_size, iv),
                annual_position_size: EncryptionService.encrypt(position.annual_position_size, iv),
                payable: position.payable || [],
                icon: template['template-icon'] || "fa-money",
                user_tags: [],
                pre_logged: position.pre_logged || false,
                monthly_spending_check: position.monthly_spending_check || false
            }

            await addDataByKey('financial-positions', validPosition);
        })

        updateDataById('user', 0, { last_save: new Date().toISOString() });
        setChange(true);
        navigate(`/${mainLocation}`);

    }

    if (!template) {
        return <div>{t('Template not found')}</div>;
    }

    return (
        <div className="container">
            <div className="row">
                <h1 className="text-center mt-5 mb-5"><FontAwesomeIcon
                    icon={template['template-icon']}/> {t(template['template-name'])}</h1>
                    {templateQuestions.map((questionSet, index) => (
                        <div key={index} className="col-md-12">
                            <h5>{t(questionSet.question)}</h5>
                            {questionSet.questionType === 'currency' && (
                                <InputCurrency
                                    fieldKey={questionSet.fieldKey}
                                    onChange={(value) => handleInputChange(index, 'monthly_position_size', value)}
                                />
                            )}
                        </div>
                    ))}

            </div>
            <Button variant="secondary" onClick={() => window.history.back()}>
                {t('Back')}
            </Button>
            <Button variant="primary" onClick={handleSubmit}>
                {t('add-selected-positions')}
            </Button>
        </div>
    );
}

export default TemplatePositions;