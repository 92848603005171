import React, { useEffect, useState } from "react";
import {getAllData} from "../../../services/indexedDB";
import i18n from "../../../configurations/i18n";
import EncryptionService from "../../../services/EncryptionService";

function CategoryRatio({ category, ratioSize, label, color, sumKey, location_id}) {
    const [categorySum, setCategorySum] = useState(0);
    const [ratioPercentage, setRatioPercentage] = useState(0);
    const [retrievedData, setRetrievedData] = useState([]);
    const t = i18n.t.bind(i18n);


    const fetchPositions = async () => {
        try {
            const data = await getAllData(location_id);
            setRetrievedData(data.values);
        } catch (error) {
            console.error('Error fetching positions:', error);
        }
    };

    useEffect(() => {
        fetchPositions();
    }, [category]);

    const calculateCategorySum = () => {
        let sum = 0;
        retrievedData.forEach( (position) => {
            if(EncryptionService.checkIfKeyExists()) {
                sum += parseFloat(EncryptionService.decrypt(position[sumKey], position.iv));
            } else {
                sum += parseFloat(position[sumKey]);
            }
        });
        setCategorySum(sum);
    };

    const calculateRatioPercentage = () => {
        if (categorySum === 0) {
            setRatioPercentage(0);
        } else {
            setRatioPercentage((ratioSize / categorySum) * 100);
        }
    };

    useEffect(() => {
        calculateCategorySum();
    }, [retrievedData]);

    useEffect(() => {
        calculateRatioPercentage();
    }, [categorySum]);

    return (
        <div className="col-md-4 mb-4">
            <div className={`card h-100 bg-opacity-25 ${color}`}>
                <div className="card-body text-center mt-5 mb-5">
                    <h3 className="card-title">{label}</h3>
                    <h1 className="display-5">{ratioPercentage.toFixed(2)}%</h1>
                    <p className="card-text fst-italic">
                        {t("category_ratio_description")}
                    </p>
                </div>
            </div>
        </div>
    );
}

export default CategoryRatio;
