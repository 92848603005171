import { useTranslation } from 'react-i18next';

function MonthNameGiver({ month }) {
    const { t } = useTranslation();

    const monthNames = [
        t('january'),
        t('february'),
        t('march'),
        t('april'),
        t('may'),
        t('june'),
        t('july'),
        t('august'),
        t('september'),
        t('october'),
        t('november'),
        t('december')
    ];

    return monthNames[month];
}

export default MonthNameGiver;
