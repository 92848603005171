import React, { useState, useEffect } from 'react';
import { useTranslation } from 'react-i18next';
import { getDataById, updateDataById, addDataByKey } from '../../services/indexedDB';
import Loading from "../../components/generic-view-components/loading/Loading";
import EncryptionService from '../../services/EncryptionService';
import useDecryption from '../../custom-hooks/useDecryption';

function UserSettings() {
    const { t, i18n } = useTranslation();
    const [currency, setCurrency] = useState('');
    const [language, setLanguage] = useState('');
    const [location, setLocation] = useState('');
    const [username, setUsername] = useState('');
    const [message, setMessage] = useState('');
    const [payday, setPayday] = useState(0);
    const [userId, setUserId] = useState(null);
    const [loading, setLoading] = useState(true);
    const [encryptionMode, setEncryptionMode] = useState(false);
    const [salt, setSalt] = useState('');

    const fetchUser = async () => {
        try {
            const userData = await getDataById('user', 0);
            if (userData) {
                setUsername(EncryptionService.decrypt(userData.username, userData.iv));
                setCurrency(userData.currency);
                setLanguage(userData.language);
                setPayday(userData.payday);
                setLocation(userData.location);
                setUserId(userData.id);
                setSalt(userData.salt);
                setEncryptionMode(userData.encryptionMode);
                i18n.changeLanguage(userData.language);
            }
            setLoading(false);
        } catch (error) {
            console.error('Error fetching user data:', error);
            setLoading(false);
        }
    };

    const handleSubmit = async (e) => {
        e.preventDefault();

        var userData = {};

        try {
            if(EncryptionService.checkIfKeyExists()) {
                const iv = EncryptionService.generateIv();
                const encryptedUsername = await EncryptionService.encrypt(username, iv);
                userData = {
                    username: encryptedUsername,
                    currency,
                    language,
                    payday,
                    id: userId ?? 0,
                    iv: iv,
                    salt: salt,
                    encryptionMode: encryptionMode
                };
            } else {
                userData = {
                    username: username,
                    currency,
                    language,
                    payday,
                    id: userId ?? 0
                };
            }

            if (userId === null) {
                await addDataByKey('user', userData);
            } else {
                await updateDataById('user', 0, userData);
            }

            setMessage(t('settings_updated_successfully'));
        } catch (error) {
            console.error('Error updating user data:', error);
            setMessage(t('error_updating_settings'));
        }
    };

    useEffect(() => {
        fetchUser();
    }, [message]);

    if (loading) {
        return <Loading />;
    }

    return (
        <div className="container user-settings-container">
            <div className="row">
                <h1 className="text-center mt-5 mb-5">{t('user_settings')}</h1>
                <form onSubmit={handleSubmit}>
                    <div className="mb-3">
                        <label className="form-label">{t('username')}</label>
                        <input
                            type="text"
                            className="form-control"
                            value={username}
                            name="username"
                            onChange={(e) => setUsername(e.target.value)}
                            required
                        />
                    </div>
                    <div className="mb-3">
                        <label className="form-label">{t('payday')}</label>
                        <input
                            type="number"
                            className="form-control"
                            value={payday}
                            name="payday"
                            onChange={(e) => setPayday(e.target.value)}
                            required
                        />
                    </div>
                    <div className="mb-3">
                        <label className="form-label">{t('language')}</label>
                        <select
                            name="language"
                            className="form-select"
                            value={language}
                            onChange={(e) => setLanguage(e.target.value)}
                            required
                        >
                            <option value="de">Deutsch</option>
                            <option value="it">Italiano</option>
                            <option value="fr">Français</option>
                            <option value="en">English</option>
                            <option value="al">Albanian</option>
                        </select>
                    </div>
                    <div className="mb-3">
                        <label className="form-label">{t('default_currency')}</label>
                        <select
                            name="currency"
                            className="form-select"
                            value={currency}
                            onChange={(e) => setCurrency(e.target.value)}
                            required
                        >
                            <option value="CHF">CHF</option>
                            <option value="EUR">EUR</option>
                            <option value="USD">USD</option>
                        </select>
                    </div>
                    <div className="fixed-buttons-container">
                    <button type="submit" className="btn btn-success"><i className="bi bi-floppy" /></button>
                    </div>
                </form>
            </div>
        </div>
    );
}

export default UserSettings;