import React, { useState, useEffect } from 'react';

function InstallAppBanner() {
    const [deferredPrompt, setDeferredPrompt] = useState(null);
    const [isApp, setIsApp] = useState(false);

    useEffect(() => {
        const isStandalone = window.matchMedia('(display-mode: standalone)').matches || window.navigator.standalone;
        setIsApp(isStandalone);
    }, []);

    useEffect(() => {
        const handleBeforeInstallPrompt = (e) => {
            e.preventDefault();
            setDeferredPrompt(e);
        };

        window.addEventListener('beforeinstallprompt', handleBeforeInstallPrompt);

        return () => {
            window.removeEventListener('beforeinstallprompt', handleBeforeInstallPrompt);
        };
    }, []);

    const handleInstallClick = async () => {
        if (deferredPrompt) {
            deferredPrompt.prompt();
            const { outcome } = await deferredPrompt.userChoice;
            if (outcome === 'accepted') {
                console.log('PWA installed');
            }
            setDeferredPrompt(null);
        }
    };

    if (isApp) {
        return null;
    }

    return (
        <div className="pwa-banner">
            <div className="pwa-banner-content">
                <p>Install our PWA for a better experience!</p>
                <button id="install-button" className="btn btn-outline-success" onClick={handleInstallClick}>Install PWA</button>
            </div>
        </div>
    );
}

export default InstallAppBanner;