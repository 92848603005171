import React, { useState, useEffect } from 'react';
import TransactionCard from './TransactionCard';
import { Button, Collapse } from "react-bootstrap";
import FormattedAmount from "../../components/logical-ui-elements/FormattedAmount";
import TransactionSearch from "./TransactionSearch";
import EncryptionService from "../../services/EncryptionService";
import i18n from "../../configurations/i18n";

const TransactionSection = ({ title, type, transactions, setBudgetCheckTransactions }) => {
    const { t } = i18n;

    const [open, setOpen] = useState(false);
    const [sectionTransactions, setSectionTransactions] = useState(transactions);
    const [sectionSum, setSectionSum] = useState(0);
    const [sectionPlanned, setSectionPlanned] = useState(0);

    useEffect(() => {
        const sum = sectionTransactions.reduce((acc, transaction) => acc + parseFloat(EncryptionService.decrypt(transaction.amount_paid, transaction.iv)), 0);
        setSectionSum(sum);
    }, [transactions, sectionTransactions]);

    useEffect(() => {
        const sum = sectionTransactions.reduce((acc, transaction) => acc + parseFloat(EncryptionService.decrypt(transaction.amount_planned, transaction.iv)), 0);
        setSectionPlanned(sum);
    }, [transactions, sectionTransactions]);

    useEffect(() => {
        setSectionTransactions(transactions);
    }, [transactions, sectionTransactions]);

    return (
        <>
            <div className="row">
                <h3 className="mt-5 text-center">{t(title)}</h3>
                <p className="text-center">You have checked: {sectionTransactions.filter(transaction => transaction.isChecked).length} / {sectionTransactions.length} positions /
                Total effective amount: <FormattedAmount amount={sectionSum}/> / Total planned amount <FormattedAmount amount={sectionPlanned}/> /
                Difference: <span className={(type === "income" || type === "savings") && sectionSum > sectionPlanned ? "text-success" : "text-danger"}><FormattedAmount amount={sectionSum - sectionPlanned} /></span>
                </p>
            </div>
            <div className="row p-0 m-0">
                {sectionTransactions.length > 0 && transactions.map((transaction, index) => (
                    !transaction.isChecked && (
                        <TransactionCard
                            key={`${title}-${index}-${transaction.id}`}
                            index={index}
                            transaction={transaction}
                            allTransactions={sectionTransactions}
                            setTransactions={setBudgetCheckTransactions}
                        />
                    )
                ))}
                {sectionTransactions.filter(transaction => !transaction.isChecked).length === 0 && (
                    <div className="col-12 text-center">
                        <h1 className="display-1 text-success"><i className="bi bi-check-all"></i></h1>
                    </div>
                )}
            </div>
            {sectionTransactions.filter(transaction => transaction.isChecked).length > 0 && (
                    <>
                        <Collapse in={open}>
                            <div>
                            <TransactionSearch
                                originalTransactions={sectionTransactions.filter(transaction => transaction.isChecked)}
                                setOriginalTransactions={setSectionTransactions}
                            />
                            </div>
                        </Collapse>
                        <div className="text-center mt-0">
                            <Button
                                className="mt-5"
                                onClick={() => setOpen(!open)}
                                aria-controls="collapseExample"
                                aria-expanded={open}
                            >
                                {open ? t('transaction-section-hide-checked') : t('transaction-section-show-checked') }
                            </Button>
                        </div>
                        </>
                        )}
                    </>
                );
                };

            export default TransactionSection;