import React, { useEffect, useState } from "react";
import {getAllData} from "../../../services/indexedDB";
import i18n from "i18next";
import EncryptionService from "../../../services/EncryptionService";

function IncomeRatio({ ratioSize, color, label }) {
    const [incomeSum, setIncomeSum] = useState(0);
    const [ratioPercentage, setRatioPercentage] = useState(0);
    const [retrievedData, setRetrievedData] = useState([]);
    const { t } = i18n;

    const fetchPositions = async () => {
        try {
            const data = await getAllData( "financial-positions");
            setRetrievedData(data.values);
        } catch (error) {
            console.error('Error fetching positions:', error);
        }
    };

    useEffect(() => {
        fetchPositions();
    }, [ratioSize]);

    const calculateCategorySum = () => {
        let sum = 0;
        retrievedData.forEach((position) => {
            if (position.type === "income") {
                if(EncryptionService.checkIfKeyExists()) {
                    sum += parseFloat(EncryptionService.decrypt(position.annual_position_size, position.iv));
                } else {
                    sum += parseFloat(EncryptionService.decrypt(position.annual_position_size, position.iv));
                }
            }
        });
        setIncomeSum(sum);
    };

    const calculateRatioPercentage = () => {
        if (incomeSum === 0) {
            setRatioPercentage(0);
        } else {
            setRatioPercentage((ratioSize / incomeSum) * 100);
        }
    };

    useEffect(() => {
        calculateCategorySum();
    }, [retrievedData]);

    useEffect(() => {
        calculateRatioPercentage();
    }, [incomeSum]);

    return (
        <div className="col-md-4 mb-4">
            <div className={`card h-100 bg-opacity-25 ${color}`}>
                <div className="card-body text-center mt-5 mb-5">
                    <h3 className="card-title">{t(label)}</h3>
                    <h1 className="display-5">{ratioPercentage.toFixed(2)}%</h1>
                    <p className="card-text fst-italic">
                        {t('income_ratio_description')}
                    </p>
                </div>
            </div>
        </div>
    );
}

export default IncomeRatio;
