// frontend/src/components/ProtectedRoute.js
import React from 'react';
import { Navigate } from 'react-router-dom';
import { getAllData } from "../services/indexedDB";

const ProtectedRoute = ({ children }) => {
    const token = getAllData("user")
    return token ? children : <Navigate to="/login" />;
};

export default ProtectedRoute;
