import React, {useState, useEffect} from 'react';
import { useTranslation } from 'react-i18next';
import FormatDate from './../../../components/logical-ui-elements/FormatDate';

function WelcomeInfo({ icon, title, addedInfo, color }) {
    const { t } = useTranslation();
    const [displayTitle, setDisplayTitle] = useState('');
    const [displayAddedInfo, setDisplayAddedInfo] = useState('');

    useEffect(() => {
        setDisplayTitle(title);
        setDisplayAddedInfo(addedInfo);
    }, [title, addedInfo]);

    return (
        <div className="col-md-4 mb-4">
            <div className={`card h-100 bg-opacity-25 ${color}`}>
                <div className="card-body text-center mt-5 mb-5">
                    <h3 className="card-title">{t("welcome_info_title")}</h3>
                    <h1 className="display-5">
                        {t(displayTitle)}
                    </h1>
                    <p className="fst-italic">{t('dashboard-widget-last-save')} {displayAddedInfo}</p>
                </div>
            </div>
        </div>
    );
}

export default WelcomeInfo;