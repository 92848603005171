import React from "react";
import i18n from "../../configurations/i18n";

function InputRadioButton({ fieldKey, options, callback, value }) {

    const t = i18n.t.bind(i18n);
    return (
        <div className="mb-3">
            {options.map((option, index) => (
                <div
                    key={index}
                    className="form-check form-check-inline"
                >
                    <input
                        className="form-check-input"
                        type="radio"
                        id={option.key}
                        name={fieldKey}
                        value={option.key}
                        checked={value === option.key}
                        onChange={callback}
                    />
                    <label
                        className="form-check-label"
                        htmlFor={option.key}>
                        {t(option.key)}
                    </label>
                </div>
            ))}
        </div>
    )
}

export default InputRadioButton;