import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import React, { useState, useEffect } from "react";
import { useTranslation } from 'react-i18next';
import * as Icons from "@fortawesome/free-solid-svg-icons";

// Define an array of icon values with labels
const iconValues = [
  { value: "address-book", label: "Address Book" },
  { value: "bed", label: "Bed" },
  { value: "bed-pulse", label: "Bed Pulse" },
  { value: "bicycle", label: "Bicycle" },
  { value: "bitcoin-sign", label: "Bitcoin Sign" },
  { value: "bone", label: "Bone" },
  { value: "book", label: "Book" },
  { value: "bottle-water", label: "Bottle Water" },
  { value: "bowel-food", label: "Bowel Food" },
  { value: "brain", label: "Brain" },
  { value: "briefcase", label: "Briefcase" },
  { value: "building", label: "Building" },
  { value: "burger", label: "Burger" },
  { value: "bus", label: "Bus" },
  { value: "cake-candles", label: "Cake Candles" },
  { value: "calendar", label: "Calendar" },
  { value: "calendar-days", label: "Calendar Days" },
  { value: "car", label: "Car" },
  { value: "carrot", label: "Carrot" },
  { value: "car-burst", label: "Car Burst" },
  { value: "cart-shopping", label: "Cart Shopping" },
  { value: "cat", label: "Cat" },
  { value: "champagne-glasses", label: "Champagne Glasses" },
  { value: "chart-simple", label: "Chart Simple" },
  { value: "chess", label: "Chess" },
  { value: "child-reaching", label: "Child Reaching" },
  { value: "children", label: "Children" },
  { value: "church", label: "Church" },
  { value: "cloud", label: "Cloud" },
  { value: "cloud-sun", label: "Cloud Sun" },
  { value: "compass", label: "Compass" },
  { value: "computer", label: "Computer" },
  { value: "credit-card", label: "Credit Card" },
  { value: "dice", label: "Dice" },
  { value: "dog", label: "Dog" },
  { value: "dragon", label: "Dragon" },
  { value: "drumstick-bite", label: "Drumstick Bite" },
  { value: "earth-americas", label: "Earth Americas" },
  { value: "envelope", label: "Envelope" },
  { value: "eye", label: "Eye" },
  { value: "film", label: "Film" },
  { value: "fire", label: "Fire" },
  { value: "flask", label: "Flask" },
  { value: "flask-vial", label: "Flask Vial" },
  { value: "gamepad", label: "Gamepad" },
  { value: "gavel", label: "Gavel" },
  { value: "gift", label: "Gift" },
  { value: "glasses", label: "Glasses" },
  { value: "globe", label: "Globe" },
  { value: "guitar", label: "Guitar" },
  { value: "hat-wizard", label: "Hat Wizard" },
  { value: "headphones", label: "Headphones" },
  { value: "helicopter", label: "Helicopter" },
  { value: "heart", label: "Heart" },
  { value: "hospital", label: "Hospital" },
  { value: "hotel", label: "Hotel" },
  { value: "house", label: "House" },
  { value: "house-chimney", label: "House Chimney" },
  { value: "house-laptop", label: "House Laptop" },
  { value: "house-tsunami", label: "House Tsunami" },
  { value: "icons", label: "Icons" },
  { value: "ice-cream", label: "Ice Cream" },
  { value: "key", label: "Key" },
  { value: "landmark", label: "Landmark" },
  { value: "martini-glass-citrus", label: "Martini Glass Citrus" },
  { value: "microphone", label: "Microphone" },
  { value: "money-bill-trend-up", label: "Money Bill Trend Up" },
  { value: "money-bill-wave", label: "Money Bill Wave" },
  { value: "mug-hot", label: "Mug Hot" },
  { value: "mug-saucer", label: "Mug Saucer" },
  { value: "music", label: "Music" },
  { value: "palette", label: "Palette" },
  { value: "paperclip", label: "Paperclip" },
  { value: "paint-roller", label: "Paint Roller" },
  { value: "pepper-hot", label: "Pepper Hot" },
  { value: "phone-volume", label: "Phone Volume" },
  { value: "photo-film", label: "Photo Film" },
  { value: "pizza-slice", label: "Pizza Slice" },
  { value: "plane-departure", label: "Plane Departure" },
  { value: "podcast", label: "Podcast" },
  { value: "puzzle-piece", label: "Puzzle Piece" },
  { value: "road", label: "Road" },
  { value: "ruler-combined", label: "Ruler Combined" },
  { value: "satellite", label: "Satellite" },
  { value: "scale-balanced", label: "Scale Balanced" },
  { value: "scissors", label: "Scissors" },
  { value: "section", label: "Section" },
  { value: "seedling", label: "Seedling" },
  { value: "shirt", label: "Shirt" },
  { value: "smoking", label: "Smoking" },
  { value: "snowflake", label: "Snowflake" },
  { value: "splotch", label: "Splotch" },
  { value: "stapler", label: "Stapler" },
  { value: "stethoscope", label: "Stethoscope" },
  { value: "sun", label: "Sun" },
  { value: "suitcase-rolling", label: "Suitcase Rolling" },
  { value: "tags", label: "Tags" },
  { value: "temperature-three-quarters", label: "Temperature Three Quarters" },
  { value: "tooth", label: "Tooth" },
  { value: "toolbox", label: "Toolbox" },
  { value: "tower-cell", label: "Tower Cell" },
  { value: "train", label: "Train" },
  { value: "umbrella", label: "Umbrella" },
  { value: "utensils", label: "Utensild" },
  { value: "user-secret", label: "User Secret" },
  { value: "user", label: "User" },
  { value: "vault", label: "Vault" },
  { value: "video", label: "Video" },
  { value: "volcano", label: "Volcano" },
  { value: "wallet", label: "Wallet" },
  { value: "water", label: "Water" },
  { value: "weight-scale", label: "Weight Scale" },
  { value: "wheelchair", label: "Wheelchair" },
  { value: "wine-bottle", label: "Wine Bottle" },
  { value: "wine-glass", label: "Wine Glass" }
];

const InputIconProvider = ({ currentValue, name, onChange }) => {
  const { t } = useTranslation();
  const [inputValue, setInputValue] = useState(currentValue || "calendar");
  const [selectedIcon, setSelectedIcon] = useState(currentValue);

  const handleInputChange = (e) => {
    setInputValue(e.target.value);
    const icon = iconValues.find(icon => t(icon.label) === e.target.value);
    if (icon) {
      setSelectedIcon(icon.value);
      onChange(name, icon.value);
    }
  };

  useEffect(() => {
    setSelectedIcon(currentValue);
  }, [currentValue]);

  return (
      <div className="input-group mb-3">
        <span className="input-group-text" id="basic-addon2">
        {selectedIcon && <FontAwesomeIcon icon={currentValue}/>}
      </span>
        <input
            type="text"
            className="form-control"
            list="iconList"
            value={inputValue}
            placeholder={t("Start typing to search for an icon...")}
            aria-label={t("Start typing to search for an icon...")}
            onChange={handleInputChange}
        />
        <datalist id="iconList">
          {iconValues.map((icon, index) => (
              <option key={index} value={t(icon.label)}>
                {t(icon.label)}
              </option>
          ))}
        </datalist>

      </div>
  );
};

export default InputIconProvider;