// A funciton to calculate values together from provided data array with given criteria
import EncryptionService from "./EncryptionService";

export function getDistinctValues(data, criteriaKey) {
    if (data.length === 0) return [];

    const distinctValues = new Set();

    data.forEach((item) => {
        const decryptedValue = EncryptionService.decrypt(item[criteriaKey], item.iv);
        distinctValues.add(decryptedValue);
    });

    return Array.from(distinctValues);
}

export function distinctValueSummarizer(data, criteriaKey, sumKey) {
    if (data.length === 0) return {};

    const result = {};

    data.forEach((item) => {
        const arrayKey = EncryptionService.decrypt(item[criteriaKey], item.iv);
        const value = EncryptionService.decrypt(item[sumKey], item.iv);

        if (!isNaN(value)) {
            if (!result[arrayKey]) {
                result[arrayKey] = 0;
            }
            result[arrayKey] += parseFloat(value);
        }
    });

    return result;
}

// From a given array of sums, this function will return the relative portion of each sum
export function relativePortionCalculator(sums) {
    const total = Object.values(sums).reduce((acc, val) => acc + val, 0);
    const result = {};

    Object.keys(sums).forEach((key) => {
        result[key] = (sums[key] / total) * 100;
    });

    return result;
}

// From a given array, the function will return only the filtered values
export function filterData(data, filterKey, filterCriteria) {
    return data.filter((item) => EncryptionService.decrypt(item[filterKey], item.iv) === filterCriteria);
}

// From a given array, the function will remove the filtered values
export function removeArrayValues(data, filterKey, filterCriteria) {
    return data.filter((item) => {
        const decryptedValue = EncryptionService.decrypt(item[filterKey], item.iv);
        return decryptedValue !== filterCriteria.toString();
    });
}

// From a given array this function sorts the data by a given key and sort order and returns the sorted array
export function sortData(data, sortKey, sortOrder) {
    return data.sort((a, b) => {
        if (sortOrder === "asc") {
            return a[sortKey] - b[sortKey];
        } else {
            return b[sortKey] - a[sortKey];
        }
    });
}

export function subtractMatchingValues(array1 = [], array2 = []) {
    const result = [];

    if(array1.length === 0 || array2.length === 0) {
        return [];
    }

    Object.keys(array1).forEach(key1 => {
        Object.keys(array2).forEach(key2 => {
            if(key1 === key2) {
                result[key1] = array1[key1] - array2[key2];
            }
        });
    });

    return result;
}