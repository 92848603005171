import React, {useEffect, useState} from 'react';
import FormattedAmount from "../../../components/logical-ui-elements/FormattedAmount";
import {getAllData} from "../../../services/indexedDB";

function CategoryInfo({label, category, color}) {

    return (
        <div className="col-md-4 mb-4">
            <div className={`card h-100 bg-opacity-25 ${color}`}>
                <div className="card-body text-center mt-5 mb-5">
                    <h3>{label}</h3>
                    <h1 className="display-5">{category}</h1>
                </div>
            </div>
        </div>
    );
}

export default CategoryInfo;