import React, { useState, useEffect } from "react";
import { Tooltip } from 'react-tooltip';
import i18n from "../../configurations/i18n";

const InputMonthSelector = ({fieldName, months, onSelect, tooltip, label, disabled} ) => {
    const [values, setValues] = useState([0, 1, 2, 3, 4, 5, 6, 7, 8, 9, 10, 11]);
    const [name, setName] = useState('');
    const [labelName, setLabelName] = useState("Monthly Recurring Payment");
    const [disabledState, setDisabledState] = useState(disabled);
    const month_names = ["January", "February", "March", "April", "May", "June", "July", "August", "September", "October", "November", "December"];
    const { t } = i18n;

    useEffect(() => {
        setName(fieldName);
        setLabelName(label || "Monthly Recurring Payment");
            if (Array.isArray(months)) {
                if (months.length === 12) {
                    setValues([0, 1, 2, 3, 4, 5, 6, 7, 8, 9, 10, 11]);
                } else if (months.length === 0) {
                    setValues([]);
                } else {
                    setValues(months);
                }
            }
    }, []);

    const handleSwitch =( e ) => {
        if(e.target.checked) {
            setValues([0, 1, 2, 3, 4, 5, 6, 7, 8, 9, 10, 11]);
        } else {
            setValues([]);
        }
    }

    const handleCheckbox = (e) => {
        const index = parseInt(e.target.value);
        if(values.includes(index)) {
            setValues(values.filter(value => value !== index));
        } else {
            setValues([...values, index]);
        }
    }

    useEffect(() => {
        onSelect(fieldName, values);
    }, [values, fieldName]);

    return (
        <div>
            <div className="form-check form-switch">
                <input
                    className="form-check-input"
                    type="checkbox"
                    role="switch"
                    id="monthlySwitch"
                    checked={values.length === 12}
                    onChange={handleSwitch}
                />
                <label className="form-check-label" htmlFor="monthlySwitch">{labelName}

                </label>
                {tooltip && (
                    <>
                    <i className="bi bi-question-circle ms-1" data-tooltip-id={`tooltip-${name}`}></i>
                    <Tooltip id={`tooltip-${name}`} place="right" effect="solid">
                        {t(tooltip)}
                    </Tooltip>
                    </>
                )}
            </div>
            {values.length < 12 &&
                <div className="form-check">
                {month_names.map((month, index) => (
                    <div className="form-check" key={index}>
                        <input
                            className="form-check-input"
                            type="checkbox"
                            value={index}
                            id={`flexCheck${index}`}
                            checked={values.includes(index)}
                            onChange={handleCheckbox}
                        />
                        <label
                            className="form-check-label"
                            htmlFor={`flexCheck${index}`}>
                            {month}
                        </label>
                    </div>
                ))}
            </div>
            }
        </div>
    )
}

export default InputMonthSelector;
