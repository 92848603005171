import React, {useEffect, useState} from 'react';
import {getAllData} from "../../services/indexedDB";
import InputCurrency from "../../components/logical-ui-elements/InputCurrency";
import {FontAwesomeIcon} from "@fortawesome/react-fontawesome";
import FormattedAmount from "../../components/logical-ui-elements/FormattedAmount";
import InputTextField from "../../components/logical-ui-elements/InputTextField";

function DailySpending() {

    const [dailySpendingPositions, setDailySpendingPositions] = useState([])

    const fetchData = async () => {
        const fetchedData = await getAllData("financial-positions")
        setDailySpendingPositions(fetchedData.values.filter(item => item.monthly_spending_check))
    }

    useEffect(() => {
        fetchData()
    }, [])

    return (
        <div className="container">
            <div className="row">
                <div className="col">
                    <h1 className="mt-5 text-center">Daily Spending</h1>
                    <hr />
                </div>
            </div>
            <div className="row">
                <div className="col">
                    <h2 className="mt-5 text-center">- Month Year -</h2>
                </div>
            </div>
            <div className="row">
                <div className="col">
                    {dailySpendingPositions.map((item, index) => {
                        return(
                        <div className="card p-0 mb-3">
                            <div className="card-header"><FontAwesomeIcon icon={item.icon} /> {item.title}</div>
                            <div className="card-body">
                                <InputCurrency
                                    value={item.monthly_position_size}
                                />
                            </div>
                            <div className="card-footer d-flex justify-content-between align-items-center">
                                <span>Remaining: <FormattedAmount amount={item.monthly_position_size}/></span>
                                <input
                                    type="checkbox"
                                    className="btn-check"
                                    id={`btn-check-outlined-${index}`}
                                    autoComplete="off"
                                />
                                <label
                                    className="btn btn-outline-success"
                                    htmlFor={`btn-check-outlined-${index}`}>
                                    All spendings tracked
                                </label>
                            </div>
                        </div>
                        )
                    })}
                </div>
            </div>

        </div>
    );
}

export default DailySpending;