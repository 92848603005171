import React, { useState, useEffect } from 'react';
import { useNavigate, useLocation, Link } from 'react-router-dom';
import JsonHandlingService from "../services/JsonHandlingService";
import userSessionManager from "../services/userSessionManager";
import EncryptionService from "../services/EncryptionService";
import { isIndexedDBPresent, getDataById } from "../services/indexedDB";
import i18n from "../configurations/i18n";
import InstallAppBanner from "./InstallAppBanner";

function Login() {
    const [username, setUsername] = useState('');
    const [password, setPassword] = useState('');
    const [file, setFile] = useState(null);
    const [error, setError] = useState('');
    const [encryptionMode, setEncryptionMode] = useState(false);
    const [user, setUser] = useState(null);
    const [isApp, setIsApp] = useState(false);

    const navigate = useNavigate();
    const location = useLocation();
    const [indexedDBPresent, setIndexedDBPresent] = useState(false);
    const [persistentIndexedDBState, setPersistentIndexedDBState] = useState(false);
    const [message, setMessage] = useState('');
    const { t } = i18n;

    useEffect(() => {
        const checkDB = async () => {
            const dbPresent = await isIndexedDBPresent();
            setIndexedDBPresent(dbPresent);
            setPersistentIndexedDBState(dbPresent);
        }

        checkDB();
    }, []);

    useEffect(() => {
        if (indexedDBPresent) {
            checkUserEncryption();
            setMessage(indexedDBPresent ? t('login-message-continue-with-existing-data') : '');
        }
    }, [indexedDBPresent]);

    useEffect(() => {
        if (location.state && location.state.message) {
            setError(location.state.message);
        }
    }, [location.state]);

    useEffect(() => {
        const isStandalone = window.matchMedia('(display-mode: standalone)').matches || window.navigator.standalone;
        setIsApp(isStandalone);
    }, []);

    const checkUserEncryption = async () => {
        try {
            const user = await getDataById('user', 0);
            setUser(user);
            setEncryptionMode(user.encryptionMode);
        } catch (error) {
            console.error("Error fetching user from IndexedDB:", error);
            return null;
        }
    };

    const handleFileChange = async (e) => {
        const uploadedFile = e.target.files[0];
        setFile(uploadedFile);

        try {
            const json = await readFileAsJson(uploadedFile);
            const user = json.user[0]; // Assuming there's only one user in the array
            setUser(user);
            setEncryptionMode(user.encryptionMode || false);
        } catch (error) {
            console.error("Error parsing JSON:", error);
            setError('Invalid JSON file');
        }
    };

    const readFileAsJson = (file) => {
        return new Promise((resolve, reject) => {
            const reader = new FileReader();
            reader.onload = (e) => {
                try {
                    const json = JSON.parse(e.target.result);
                    resolve(json);
                } catch (error) {
                    reject(error);
                }
            };
            reader.readAsText(file);
        });
    };

    const handleSubmit = async (e) => {
        e.preventDefault();
        let userData = user;

        if (indexedDBPresent) {
            userData = await getDataById("user", 0);
            if (!userData) {
                setError('User not found in IndexedDB');
                return;
            }
        }

        if (indexedDBPresent || file) {
            const isValidUser = await checkUserCredentials(username, password, userData);
            if (isValidUser) {
                var key = "";
                if (userData.encryptionMode) {
                    key = await EncryptionService.generateKey(password, userData.salt);
                }
                await userSessionManager.openSession(key);
                if (file) {
                    await JsonHandlingService.importDataFromJsonFile(file);
                }
                navigate('/dashboard');
            } else {
                setError('Invalid username or password');
            }
        } else {
            setError('Please upload a valid .json file');
        }
    };

    const checkUserCredentials = async (username, password, userData) => {
        try {
            if (userData.encryptionMode) {
                const key = await EncryptionService.generateKey(password, userData.salt);
                const encryptedUsername = EncryptionService.encrypt(username, userData.iv, key);
                return encryptedUsername === userData.username;
            } else {
                return true;
            }
        } catch (error) {
            console.error("Error checking user credentials:", error);
            return false;
        }
    };

    return (
        <div className="container mt-5">
            <div className="row justify-content-center">
                {indexedDBPresent === false ? (
                <div className="col-md-6">
                    <div className="card">
                        <div className="card-body">
                            {persistentIndexedDBState === true ? (
                                <div className="alert alert-info">{t("login-message-existing-data")}</div>
                            ) : null}
                            <form onSubmit={handleSubmit}>
                                <div className="form-group mb-3">
                                    <label htmlFor="file">{t('login-label-upload-file')}</label>
                                    <input
                                        type="file"
                                        className="form-control"
                                        id="file"
                                        onChange={handleFileChange}
                                        required={true}
                                    />
                                </div>
                                {persistentIndexedDBState === true ? (
                                    <div className="alert alert-warning">{t("login-message-overwritten-unsaved-lost")}</div>
                                ) : null}
                                {encryptionMode && (
                                    <div className="me-3 ms-3">
                                        <div className="form-group mb-3">
                                            <label htmlFor="username">{t('login-label-username')}</label>
                                            <input
                                                type="text"
                                                className="form-control"
                                                id="username"
                                                value={username}
                                                onChange={(e) => setUsername(e.target.value)}
                                                required
                                            />
                                        </div>
                                        <div className="form-group mb-3">
                                            <label htmlFor="password">{t('login-label-password')}</label>
                                            <input
                                                type="password"
                                                className="form-control"
                                                id="password"
                                                value={password}
                                                onChange={(e) => setPassword(e.target.value)}
                                                required
                                            />
                                        </div>
                                    </div>
                                )}
                                {error && <div className="alert alert-danger">{error}</div>}
                                <button type="submit"
                                        className="btn btn-primary mt-3">{t("login-button-login")}</button>
                            </form>
                            {persistentIndexedDBState === true ? (
                                <button className="btn btn-secondary mt-3"
                                        onClick={() => setIndexedDBPresent(!indexedDBPresent)}>{t("login-button-continue-existing-data")}</button>
                            ) : null}
                            <Link to="/onboarding" className="btn btn-warning mt-5">{t("login-button-start-new")}</Link>
                        </div>
                    </div>
                </div>
                ) : (
                    <div className="col-md-6">
                        <div className="card">
                            <div className="card-body">
                                {message && <div className="alert alert-info">{message}</div>}
                                <form onSubmit={handleSubmit}>
                            {encryptionMode && (
                                <div className="me-3 ms-3">
                                    <div className="form-group mb-3">
                                        <label htmlFor="username">{t('login-label-username')}</label>
                                        <input
                                            type="text"
                                            className="form-control"
                                            id="username"
                                            value={username}
                                            onChange={(e) => setUsername(e.target.value)}
                                            required
                                        />
                                    </div>
                                    <div className="form-group mb-3">
                                        <label htmlFor="password">{t('login-label-password')}</label>
                                        <input
                                            type="password"
                                            className="form-control"
                                            id="password"
                                            value={password}
                                            onChange={(e) => setPassword(e.target.value)}
                                            required
                                        />
                                    </div>
                                </div>
                            )}
                                {error && <div className="alert alert-danger">{error}</div>}
                                <button type="submit" className="btn btn-primary mt-3">{t("login-button-login")}</button>
                                </form>
                                <button className="btn btn-secondary mt-3" onClick={() => setIndexedDBPresent(!indexedDBPresent)}>{t("login-button-open-other-budget")}</button>
                            </div>
                            <InstallAppBanner />
                        </div>
                    </div>
                )}
            </div>
        </div>
    );
}

export default Login;