import React from "react";
import {Tooltip} from "react-tooltip";
import i18n from "../../configurations/i18n";

function InputSwitch({ fieldKey, checked, tooltip, label, required, callback }) {

    const { t } = i18n;

    return (
        <div className="form-check form-switch">
            <input className="form-check-input" type="checkbox" role="switch"
                   id={fieldKey}
                   name={fieldKey}
                   checked={checked !== undefined ? checked : false}
                   required={required}
                   onChange={(e) => callback(e)}
            />
            {label && (
                <label htmlFor={fieldKey} className="form-label">{t(label)}</label>
            )}
            {tooltip && (
                <>
                    <i className="bi bi-question-circle ms-1" data-tooltip-id={`tooltip-${fieldKey}`}></i>
                    <Tooltip id={`tooltip-${fieldKey}`} place="right" effect="solid">
                        {t(tooltip)}
                    </Tooltip>
                </>
            )}

        </div>
    );
}

export default InputSwitch;