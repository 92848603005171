// frontend/src/core-components/Logout.js
import React, { useEffect } from 'react';
import { useNavigate } from 'react-router-dom';
import JsonFileExport from "../components/logical-ui-elements/JsonFileExport";

function Logout() {
    const navigate = useNavigate();

    return (
        <JsonFileExport />
    );
}

export default Logout;
