import React, { useEffect, useState } from 'react';
import { getAllData } from '../../../services/indexedDB';
import FormattedAmount from "../../../components/logical-ui-elements/FormattedAmount";
import i18n from '../../../configurations/i18n';
import EncryptionService from "../../../services/EncryptionService";

const AllocationInfo = ({ accountId, title, showInflow, color }) => {
    const [monthlyAmount, setMonthlyAmount] = useState(0); // Set initial state to null
    const { t } = i18n;

    useEffect(() => {
        const fetchData = async () => {
            const { values } = await getAllData('financial-positions');
            const totalMonthlyAmount = values
                .filter(position => {
                    const isAccountMatch = parseInt(position.receiving_account) === accountId;
                    const isIncomeMatch = position.isIncome === showInflow;
                    return isAccountMatch && isIncomeMatch;
                })
                .reduce((sum, position) => sum + (parseFloat(EncryptionService.decrypt(position.monthly_position_size, position.iv)) || 0), 0);

            console.log("totalMonthlyAmount", totalMonthlyAmount)
            setMonthlyAmount(totalMonthlyAmount);
        };

        fetchData();
    }, [accountId, showInflow]);

    if (!accountId) {
        return null; // Render nothing if monthlyAmount is null
    }

    return (
        <div className="col-md-4 mb-4">
            <div className={`card h-100 bg-opacity-25 ${color}`}>
                <div className="card-body text-center mt-5 mb-5">
                    <h3>{title}</h3>
                    <h1 className="card-title display-5">
                        <FormattedAmount amount={monthlyAmount}/>
                    </h1>
                </div>
            </div>
        </div>
    );
};

export default AllocationInfo;