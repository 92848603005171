import React from "react";
import "./Footer.css";

function Footer() {
  const currentYear = new Date().getFullYear();
  const companyName = "App by Dom";
  const appVersion = "0.5";

  return (
    <footer className="FooterComponent text-center mt-5">
      <p className="fst-italic">&copy; {currentYear} {companyName}. All rights reserved. Version: {appVersion}</p>
    </footer>
  );
}

export default Footer;