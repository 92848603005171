import React from "react";
import './BudgetScore.css';

function BudgetScore({ score = 5 }) {
  const maxStars = 5;
  const fullStars = Math.floor(score);
  const halfStar = score % 1 >= 0.5 ? 1 : 0;
  const emptyStars = maxStars - fullStars - halfStar;

  return (
    <div className="BudgetScoreComponent">
          {Array.from({ length: fullStars }).map((_, index) => (
            <i key={`full-${index}`} className="bi bi-star-fill ms-1 me-1" />
          ))}
          {halfStar === 1 && <i className="bi bi-star-half ms-1 me-1" />}
          {Array.from({ length: emptyStars }).map((_, index) => (
            <i key={`empty-${index}`} className="bi bi-star ms-1 me-1" />
          ))}
    </div>
  );
}

export default BudgetScore;