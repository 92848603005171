import {useState, useEffect} from "react";
import {getDataById} from "../../services/indexedDB";

const FormattedAmount = ( {amount} ) => {

    const [currency, setCurrency] = useState( 'CHF');
    const [posAmount, setPosAmount] = useState(amount || 0);

    const getUserCurrency = async () => {
        const user = await getDataById("user", 0);
        if(user) {
            setCurrency(user.currency);
        }
    }

    useEffect(() => {
        if(posAmount < 0) {
            setPosAmount(posAmount * -1 || 0);
        } else {
            setPosAmount(posAmount || 0);
        }
        getUserCurrency();
    }, [posAmount]);

    const formatter = new Intl.NumberFormat('de-CH', {
        style: 'currency',
        currency: currency,
        minimumFractionDigits: 2,
        maximumFractionDigits: 2,
    });

    const formattedAmount = formatter.format(amount);

    return (
        <span className="formatted-amount">{formattedAmount}</span>
    )

}

export default FormattedAmount;
