import React, {useState, useEffect} from 'react';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import InputCurrency from '../../components/logical-ui-elements/InputCurrency';
import {updateDataById} from "../../services/indexedDB";
import EncryptionService from "../../services/EncryptionService";

const TransactionCard = ({ transaction, index, setTransactions}) => {

    const [amountPaid, setAmountPaid] = useState(0);
    const [isChecked, setIsChecked] = useState(transaction.isChecked);

    useEffect(() => {
        setAmountPaid(parseFloat(EncryptionService.decrypt(transaction.amount_paid, transaction.iv)));
        setIsChecked(transaction.isChecked);
    }, [transaction]);

    const handleCurrencyChange = (e) => {
        setAmountPaid(parseFloat(e.target.value));
    }

    const handleSave = (e, id) => {
        const updatedChecked = !isChecked;
        const EncryptedAmountPaid = EncryptionService.encrypt(amountPaid.toString(), transaction.iv);
        setIsChecked(updatedChecked)
        updateDataById('transactions', id, { ...transaction, isChecked: updatedChecked, amount_paid: EncryptedAmountPaid });
        setTransactions(prevState => {
            return prevState.map(item => {
                if (item.id === id) {
                    console.log("found entry");
                    return { ...item, isChecked: updatedChecked, amount_paid: EncryptedAmountPaid };
                }
                return item;
            });
        });
    }


    return (
        <div className="col-12 col-md-6 col-lg-4">
            <div className="card mt-4 p-0">
                <div className="card-header d-flex justify-content-between align-items-center">
                    <span><FontAwesomeIcon icon={transaction.icon} /> {EncryptionService.decrypt(transaction.title, transaction.iv)}</span>
                    <span>{isChecked ? <i className="bi bi-check2-square text-success" /> : <i className="bi bi-square text-warning" />}</span>
                </div>
                <div className="card-body text-center">
                    <InputCurrency
                        showCurrency={true}
                        value={amountPaid}
                        id={`amount-${index}-${transaction.id}`}
                        name={`amount-${index}-${transaction.id}`}
                        disabled={isChecked}
                        callback={(e) => handleCurrencyChange(e, transaction.id)}
                    />
                </div>
                <div className="card-footer justify-content-between align-items-center d-flex">
                    <span className="badge bg-info">{transaction.transaction_type}</span>
                    <span>
                        <input
                            type="checkbox"
                            className="btn-check"
                            id={`amount-${index}-${transaction.id}`}
                            name={`amount-${index}-${transaction.id}`}
                            checked={isChecked}
                            onChange={(e) => handleSave(e, transaction.id)}
                        />
                        <label className="btn btn-primary" htmlFor={`amount-${index}-${transaction.id}`}><i className="bi bi-floppy" /></label>
                    </span>
                </div>
            </div>
        </div>
    );
};

export default TransactionCard;