import React, {useEffect, useState} from 'react';
import {getDataById} from "../../../services/indexedDB";
import EncryptionService from "../../../services/EncryptionService";

function CategoryInfo({label, account, color}) {

    const [idLabel, setIdLabel] = useState('');
    const [additionalInfo, setAdditionalInfo] = useState('');

    useEffect(() => {
        const fetchAccounts = async () => {
            const data = await getDataById('accounts', parseInt(account));
            if(EncryptionService.checkIfKeyExists()) {
                setIdLabel(EncryptionService.decrypt(data.title, data.iv));
                setAdditionalInfo(EncryptionService.decrypt(data.institution, data.iv));
            } else {
                setIdLabel(data.account);
                setAdditionalInfo(data.bank);
            }
        }
        fetchAccounts();
    }, [account]);

    return (
        <div className="col-md-4 mb-4">
            <div className={`card h-100 bg-opacity-25 ${color}`}>
                <div className="card-body text-center mt-5 mb-5">
                    <h3>{label}</h3>
                    <h1 className="display-5">{idLabel}</h1>
                    {additionalInfo && (
                        <h2 className="card-text">
                            ({additionalInfo})
                        </h2>
                    )}
                </div>
            </div>
        </div>
    );
}

export default CategoryInfo;