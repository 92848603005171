import React from 'react';
import FormattedAmount from "../../../components/logical-ui-elements/FormattedAmount";

function FinancialInfo({amount, label, color}) {

    return (
        <div className="col-md-4 mb-4">
            <div className={`card h-100 bg-opacity-25 ${color}`}>
                <div className="card-body text-center mt-5 mb-5">
                    <h3>{label}</h3>
                    <h1 className="card-title display-5">
                        <FormattedAmount amount={amount} />
                    </h1>
                </div>
            </div>
        </div>
    );
}

export default FinancialInfo;