import React, { useState, useEffect } from 'react';
import 'slick-carousel/slick/slick.css';
import 'slick-carousel/slick/slick-theme.css';
import Slider from 'react-slick';
import './YearSelectionSlider.css';
import MonthNameGiver from "./MonthNameGiver";

const YearMonthSelectionSlider = ({ setCurrentYear, setCurrentMonth }) => {
    const currentYear = new Date().getFullYear();
    const years = Array.from({ length: 12 }, (_, i) => currentYear - 5 + i); // 5 years back and 6 years ahead
    const months = [0,1,2,3,4,5,6,7,8,9,10,11];
    const [selectedYear, setSelectedYear] = useState(currentYear);
    const [selectedMonth, setSelectedMonth] = useState(new Date().getMonth());
    const [toDoCounter, setToDoCounter] = useState(0);

    useEffect(() => {
        setCurrentYear(selectedYear);
    }, [selectedYear]);

    useEffect(() => {
        setCurrentMonth(selectedMonth);
    }, [selectedMonth]);

    const yearSettings = {
        centerMode: true,
        focusOnSelect: true,
        arrows: true,
        initialSlide: 5,
        infinite: true,
        beforeChange: (_, next) => setSelectedYear(years[next]), // Update selected year
        slidesToShow: 3,
        speed: 500,
    };

    const monthSettings = {
        centerMode: true,
        focusOnSelect: true,
        arrows: true,
        initialSlide: selectedMonth,
        infinite: true,
        beforeChange: (_, next) => setSelectedMonth(months[next]), // Update selected year
        slidesToShow: 3,
        speed: 500,
    };

    return (
        <div className="mb-5">
            <Slider {...yearSettings}>
                {years.map(year => (
                    <div key={year}>
                        <h3 style={{ textAlign: 'center', fontSize: year === selectedYear ? '2rem' : '1rem' }}>
                            {year}
                        </h3>
                    </div>
                ))}
            </Slider>
            <Slider {...monthSettings}>
                {months.map((month, index) => (
                    <div key={index}>
                        <h3 style={{ textAlign: 'center', fontSize: index === selectedMonth ? '1rem' : '0.5rem' }}>
                            <MonthNameGiver month={month}/>
                        </h3>
                    </div>
                ))}
            </Slider>
        </div>
    );
};

export default YearMonthSelectionSlider;