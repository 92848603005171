import React from "react";
import MonthNameGiver from "../../../components/logical-ui-elements/MonthNameGiver";
import i18n from "i18next";

function PayableInfo({label, payable, color}) {

    const { t } = i18n;

    return (
        <div className="col-md-4 mb-4">
            <div className={`card h-100 bg-opacity-25 ${color}`}>
                <div className="card-body text-center mt-5 mb-5">
                    <h3>{t('due')}</h3>
                    <h2>
                        {payable.length === 0 && (
                            <MonthNameGiver
                                month={payable}
                            />
                        )}
                        {payable.length > 0 && (
                            payable.map((payable, index) => (
                                <span key={index} className="fw-light fst-italic"><MonthNameGiver
                                    month={payable}
                                /> </span>
                            ))
                        )}

                    </h2>
                </div>
            </div>
        </div>
    );
}

export default PayableInfo;