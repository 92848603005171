import React, { useEffect, useState } from "react";
import { getAllData } from "../../../services/indexedDB";
import { Link } from "react-router-dom";
import FormattedAmount from "../../../components/logical-ui-elements/FormattedAmount";
import i18n from "../../../configurations/i18n";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import './UpcomingBills.css';
import EncryptionService from "../../../services/EncryptionService";

const getUpcomingBills = (data) => {
    const currentMonth = new Date().getMonth();
    const nextMonth = (currentMonth + 1) % 12;
    const upcomingBills = [];

    data.forEach(item => {
        if (item.payable.length !== 12 && (item.type === "expense")) {
            item.payable.forEach(month => {
                if (month === currentMonth || month === nextMonth) {
                    upcomingBills.push({ ...item, month });
                }
            });
        }
    });

    return upcomingBills;
};

export const UpcomingBills = () => {
    const [retreivedData, setRetreivedData] = useState([]);
    const [upcomingBills, setUpcomingBills] = useState([]);
    const { t } = i18n;

    const fetchData = async () => {
        const data = await getAllData("financial-positions");
        setRetreivedData(data.values);
    };

    useEffect(() => {
        fetchData();
    }, []);

    useEffect(() => {
        const bills = getUpcomingBills(retreivedData);
        if (bills) {
            setUpcomingBills(bills.slice(0, 3));
        }
    }, [retreivedData]);

    return (
        <div className="col-md-4 mb-4">
            <div className={`card h-100 bg-opacity-50 bg-warning`}>
                <div className="card-body">
                    <h3 className="card-title text-center mt-5">{t('upcoming-bills')}</h3>
                    {upcomingBills.length > 0 ? (
                        <ul className="list-group no-border mt-0 mb-5">
                            {upcomingBills.map((item, index) => (
                                <Link key={index} className="text-decoration-none text-reset link-underline" to={`/financial-positions/details/${item.id}`}>
                                    <li className="list-group-item d-flex justify-content-between align-items-start bg-transparent">
                                        <div>
                                            <FontAwesomeIcon icon="fa-solid fa-link" /> {EncryptionService.decrypt(item.title, item.iv)} ({new Date(0, item.month).toLocaleString('default', { month: 'short' })})
                                        </div>
                                        <FormattedAmount amount={EncryptionService.decrypt(item.annual_position_size, item.iv) / parseInt(item.payable.length)} />
                                    </li>
                                </Link>
                            ))}
                        </ul>
                    ) : (
                        <p>No upcoming bills available</p>
                    )}
                </div>
            </div>
        </div>
    );
};

export default UpcomingBills;