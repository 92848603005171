import React, { useEffect, useState } from 'react';
import { useLocation, useParams } from 'react-router-dom';
import { useTranslation } from 'react-i18next';
import { getDataById } from "../../services/indexedDB";
import Loading from "../../components/generic-view-components/loading/Loading";
import './DetailViewPage.css';
import viewConfiguration from "../../configurations/viewConfiguration.json";
import PosInfo from "./position-detail-widgets/PosInfo";
import FinancialInfo from "./position-detail-widgets/FinancialInfo";
import CategoryInfo from "./position-detail-widgets/CategoryInfo";
import PayableInfo from "./position-detail-widgets/PayableInfo";
import ReceivingAccountInfo from "./position-detail-widgets/ReceivingAccountInfo";
import UserTagsInfo from "./position-detail-widgets/UserTagsInfo";
import CreateEditForm from "../../components/generic-view-components/create-edit-form/CreateEditForm";
import DeleteForm from "../../components/generic-view-components/delete-form/DeleteForm";
import IncomeRatio from "./position-detail-widgets/IncomeRatio";
import CategoryRatio from "./position-detail-widgets/CategoryRatio";
import YieldInfo from "./position-detail-widgets/YieldInfo";
import AllocationInfo from "./position-detail-widgets/AllocationInfo";
import EncryptionService from "../../services/EncryptionService";

function DetailViewPage( {setChange }) {
    const { t } = useTranslation();
    const locationArray = useLocation();
    const { pos_id } = useParams();
    const [detailData, setDetailData] = useState([]);
    const [viewConfig, setViewConfig] = useState([]);
    const [config_id, setConfigId] = useState(null);
    const [loading, setLoading] = useState(true);
    const [location, setLocation] = useState(null);
    const [editMode, setEditMode] = useState(false);
    const [deleteMode, setDeleteMode] = useState(false);
    const [formFieldsConfig, setFormFieldsConfig] = useState([]);
    const { state } = locationArray;
    const { monthlyRemainingIncome, annualRemainingIncome } = state || {};

    const fetchPositions = async () => {
        if(!config_id || !pos_id) {
            return;
        }

        try {
            const data = await getDataById(config_id, parseInt(pos_id));
            setDetailData(data);
            setTimeout(() => {
                setLoading(false);
            }, 1000);
        } catch (error) {
            console.error('Error fetching positions:', error);
        }
    };

    useEffect(() => {
        if (location && config_id && pos_id) {
            fetchPositions();
        }
    }, [config_id, pos_id]);

    useEffect(() => {
        if(config_id) {
            setViewConfig(viewConfiguration[config_id].detailViewConfig[0]);
        }
    }, [config_id]);

    useEffect(() => {
        setLoading(true);
        setLocation(locationArray.pathname.slice(1));
    }, [locationArray]);

    useEffect(() => {
        const parts = locationArray.pathname.split('/');
        const conf_id = parts.length > 2 ? parts[1] : null;
        setConfigId(conf_id);
        setFormFieldsConfig(viewConfiguration[conf_id].formFields);
    }, [locationArray]);

    useEffect(() => {
        if (!editMode && !deleteMode) {
            fetchPositions();
        }
    }, [editMode, deleteMode]);

    useEffect(() => {
        const handlePopState = (event) => {
            if (editMode || deleteMode) {
                setEditMode(false);
                setDeleteMode(false);
            } else {
                window.history.back();
            }
        };

        window.addEventListener('popstate', handlePopState);

        return () => {
            window.removeEventListener('popstate', handlePopState);
        };
    }, [editMode, deleteMode]);

    const handleFormSubmit = async () => {
        setEditMode(false);
        setDeleteMode(false);
    }

    if (loading) {
        return (
            <Loading />
        );
    }

    return (
        <div className="DetailViewPage container pt-5">
            {!editMode && !deleteMode ? (
                <>
                <div className="row">
                    {viewConfig.defaultWidgets.posInfo ? (
                        <PosInfo
                            icon={detailData[viewConfig.defaultWidgets.posInfo[0].icon]}
                            title={EncryptionService.decrypt(detailData[viewConfig.defaultWidgets.posInfo[0].key], detailData.iv)}
                            addedInfo={EncryptionService.decrypt(detailData[viewConfig.defaultWidgets.posInfo[0].addedInfo], detailData.iv)}
                            color={viewConfig.defaultWidgets.posInfo[0].color}
                        />
                    ) : (null)}
                    {viewConfig.defaultWidgets.financialInfo && detailData[viewConfig.defaultWidgets.financialInfo[0].key] ? (
                        <FinancialInfo
                            amount={EncryptionService.decrypt(detailData[viewConfig.defaultWidgets.financialInfo[0].key], detailData.iv)}
                            label={viewConfig.defaultWidgets.financialInfo[0].label}
                            color={viewConfig.defaultWidgets.financialInfo[0].color}
                        />
                    ) : (null)}
                    {config_id === "accounts" && pos_id ? (
                        <AllocationInfo
                            accountId={parseInt(pos_id)}
                            color="bg-success"
                            title={t('widget_monthly_inflow_account_allocation')}
                            showInflow={false}
                        />
                    ) : (null)}
                    {config_id === "accounts" && pos_id ? (
                        <AllocationInfo
                            accountId={parseInt(pos_id)}
                            color="bg-danger"
                            title={t('widget_monthly_outflow_account_allocation')}
                            showInflow={true}
                        />
                    ) : (null)}
                    {viewConfig.defaultWidgets.secondFinancialInfo && detailData[viewConfig.defaultWidgets.secondFinancialInfo[0].key] ? (
                        <FinancialInfo
                            amount={EncryptionService.decrypt(detailData[viewConfig.defaultWidgets.secondFinancialInfo[0].key], detailData.iv)}
                            label={viewConfig.defaultWidgets.secondFinancialInfo[0].label}
                            color={viewConfig.defaultWidgets.secondFinancialInfo[0].color}
                        />
                    ) : (null)}
                    {viewConfig.defaultWidgets.incomeRatio && detailData[viewConfig.defaultWidgets.incomeRatio[0].key] ? (
                        <IncomeRatio
                            ratioSize={EncryptionService.decrypt(detailData[viewConfig.defaultWidgets.incomeRatio[0].key], detailData.iv)}
                            label={viewConfig.defaultWidgets.incomeRatio[0].label}
                            color={viewConfig.defaultWidgets.incomeRatio[0].color}
                        />
                    ) : (null)}
                    {viewConfig.defaultWidgets.receivingAccountInfo && detailData[viewConfig.defaultWidgets.receivingAccountInfo[0].key] ? (
                        <ReceivingAccountInfo
                            account={detailData[viewConfig.defaultWidgets.receivingAccountInfo[0].key]}
                            label={viewConfig.defaultWidgets.receivingAccountInfo[0].label}
                            color={viewConfig.defaultWidgets.receivingAccountInfo[0].color}
                        />
                    ) : (null)}
                    {viewConfig.defaultWidgets.categoryInfo && detailData[viewConfig.defaultWidgets.categoryInfo[0].key] ? (
                        <CategoryInfo
                            category={EncryptionService.decrypt(detailData[viewConfig.defaultWidgets.categoryInfo[0].key], detailData.iv)}
                            label={viewConfig.defaultWidgets.categoryInfo[0].label}
                            color={viewConfig.defaultWidgets.categoryInfo[0].color}
                        />
                    ) : (null)}
                    {viewConfig.defaultWidgets.categoryRatio && detailData[viewConfig.defaultWidgets.categoryRatio[0].category] && detailData[viewConfig.defaultWidgets.categoryRatio[0].ratioSize] ? (
                        <CategoryRatio
                            category={EncryptionService.decrypt(detailData[viewConfig.defaultWidgets.categoryRatio[0].category], detailData.iv)}
                            ratioSize={EncryptionService.decrypt(detailData[viewConfig.defaultWidgets.categoryRatio[0].ratioSize], detailData.iv)}
                            label={viewConfig.defaultWidgets.categoryRatio[0].label}
                            color={viewConfig.defaultWidgets.categoryRatio[0].color}
                            sumKey={viewConfig.defaultWidgets.categoryRatio[0].sumKey}
                            location_id={config_id}
                        />
                    ) : (null)}
                    {viewConfig.defaultWidgets.payableInfo && detailData[viewConfig.defaultWidgets.payableInfo[0].key] ? (
                        <PayableInfo
                            payable={detailData[viewConfig.defaultWidgets.payableInfo[0].key]}
                            label={viewConfig.defaultWidgets.payableInfo[0].label}
                            color={viewConfig.defaultWidgets.payableInfo[0].color}
                        />
                    ) : (null)}
                    {viewConfig.defaultWidgets.userTagsInfo && detailData[viewConfig.defaultWidgets.userTagsInfo[0].key].length > 0 ? (
                        <UserTagsInfo
                            user_tags={detailData[viewConfig.defaultWidgets.userTagsInfo[0].key]}
                            wIv={detailData.iv}
                            label={viewConfig.defaultWidgets.userTagsInfo[0].label}
                            color={viewConfig.defaultWidgets.userTagsInfo[0].color}
                        />
                    ) : (null)}
                    {viewConfig.defaultWidgets.yieldInfo && detailData[viewConfig.defaultWidgets.yieldInfo[0].key] ? (
                        <YieldInfo
                            positionYield={EncryptionService.decrypt(detailData[viewConfig.defaultWidgets.yieldInfo[0].key], detailData.iv)}
                            label={viewConfig.defaultWidgets.yieldInfo[0].label}
                            color={viewConfig.defaultWidgets.yieldInfo[0].color}
                        />
                    ) : (null)}
                </div>
                <div className="fixed-buttons-container">
                    <button
                        className={`btn btn-success`}
                        type="button"
                        onClick={() => setEditMode(true)}
                    >
                        <i className="bi bi-pencil"></i>
                    </button>
                    <button
                        className={`btn btn-danger`}
                        type="button"
                        onClick={() => setDeleteMode(true)} // Replace '123' with the actual itemId
                    >
                        <i className="bi bi-trash"></i>
                    </button>
                </div>
                </>
            ) : editMode && !deleteMode ? (
                <div className="row">
                    <h1>{t('edit')}: {EncryptionService.decrypt(detailData[viewConfig.defaultWidgets.posInfo[0].key], detailData.iv)}</h1>
                    <hr/>
                    <CreateEditForm
                        formFields={formFieldsConfig}
                        initialValues={detailData}
                        onFormSubmit={handleFormSubmit}
                        onCancel={() => setEditMode(false)}
                        location={config_id}
                        setChange={setChange}
                        monthlyRemainingIncome={monthlyRemainingIncome}
                        annualRemainingIncome={annualRemainingIncome}
                    />
                </div>
            ) : !editMode && deleteMode ? (
                <>
                    <div className="row">
                        <h1>{t('delete')}: {EncryptionService.decrypt(detailData[viewConfig.defaultWidgets.posInfo[0].key], detailData.iv)}</h1>
                        <hr/>
                    </div>
                    <div className="row">
                        <DeleteForm
                            values={detailData}
                            onFormSubmit={handleFormSubmit}
                            onCancel={() => setDeleteMode(false)}
                            setChange={setChange}
                            location_id={config_id}
                        />
                    </div>
                </>
            ) : null
            }
        </div>
    );
}

export default DetailViewPage;