import React, {useState, useEffect} from 'react';
import ViewSearch from '../../components/logical-ui-elements/ViewSearch';
import FormattedAmount from '../../components/logical-ui-elements/FormattedAmount';
import {updateDataById} from "../../services/indexedDB";
import i18n from "../../configurations/i18n";

const TransactionSearch = ({ originalTransactions, setOriginalTransactions }) => {

    const [searchedTransactions, setSearchedTransactions] = useState([]);
    const { t } = i18n;

    useEffect(() => {
        setSearchedTransactions(originalTransactions);
    }, [originalTransactions]);

    const unCheckTransaction = (transaction) => {
        const updatedTransactions = originalTransactions.map((item) => {
            if (item.id === transaction.id) {
                item.isChecked = false;
            }
            return item;
        });
        updateDataById('transactions', transaction.id, {isChecked: false});
        setOriginalTransactions(updatedTransactions);
    }

    return (
        <>
            <div className="row">
                <div className="col">
                    <ViewSearch
                        setNewValueList={setSearchedTransactions}
                        initialValueList={originalTransactions}
                    />
                </div>
            </div>
            <div className="row">
                <div className="col">
                    {searchedTransactions.length === 0 && (
                        <div className="alert alert-warning mt-3" role="alert">
                            {t('transaction-search-no-transactions-found')}
                        </div>
                    )}
                    <ul className="list-group">
                        {searchedTransactions && searchedTransactions.map((transaction, index) => (
                            <li
                                key={index}
                                className="list-group-item d-flex justify-content-between align-middle small pt-3 pb-3"
                                aria-current="true"
                            >
                                <span>{transaction.title} (<FormattedAmount amount={transaction.amount_paid} />)</span>
                                <button
                                    className={`btn btn-warning`}
                                    onClick={() => unCheckTransaction(transaction)}
                                >
                                    <i className="bi bi-pencil"/>
                                </button>
                            </li>
                        ))}
                    </ul>
                </div>
            </div>
        </>
    );
};

export default TransactionSearch;